import React, { useEffect, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format, isSameDay, parse, subDays } from 'date-fns';

import { Button } from '@/components/Button/Button';
import { Dropdown } from '@/components/Dropdown/Dropdown';
import { CalendarIcon } from '@/components/Icons/CalendarIcon';
import { Input } from '@/components/Input/Input';
import { usePermissions } from '@/components/PermissionsContext/PermissionsContext';
import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { isValidDate } from '@/core/utils/dateTimeUtil';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { useSchemaContext } from '../SchemaContext/SchemaContext';
import { Typography } from '../Typography/Typography';

import CalendarRangePicker from './CalendarRangePicker/CalendarRangePicker';

interface DatePickerProps {
  onChange?: (date: Date) => void;
  control: UseControllerProps;
  isOpen?: boolean;
  placeholder?: string;
  disabled?: boolean;
  dateFormat?: string;
  type?: 'single' | 'range';
  className?: string;
  containerClassName?: string;
  readOnly?: boolean;
  isNavigate?: boolean;
  isFutureSelectionLocked?: boolean;
  wrapperClassName?: string;
  label?: string | React.ReactNode;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  isOpen = false,
  placeholder,
  control,
  dateFormat = FRONTEND_DATE_FORMAT,
  type = 'single',
  readOnly,
  className,
  containerClassName,
  isNavigate = false,
  isFutureSelectionLocked = false,
  wrapperClassName,
  label,
}) => {
  const { t: datepickerTranslations } = useTranslation('datepicker');

  const {
    field: { value: dateString, onChange: controllerOnChane },
  } = useController(control);

  const permissions = usePermissions(control.name);
  const { isRequiredField } = useSchemaContext(control.name);

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(isOpen);

  const selectedDate = dateString ? parse(dateString, FRONTEND_DATE_FORMAT, new Date()) : null;
  const isToday = selectedDate ? isSameDay(new Date(), selectedDate) : false;

  const handleDateChange = (date: Date) => {
    const formattedDate = format(date, FRONTEND_DATE_FORMAT);

    controllerOnChane(formattedDate);
    onChange?.(date);
    setIsCalendarOpen(false);
  };

  const handleInputChange = (previousValue: string, newValue: string) => {
    const inputDate = parse(newValue, dateFormat, new Date());
    if (isValidDate(inputDate) && newValue.length === 10) {
      handleDateChange(inputDate);
    }
    return newValue;
  };

  const handleOnFocus = () => {
    if (readOnly || permissions.isReadOnly) {
      return;
    }

    setIsCalendarOpen(true);
  };

  const handleDateNavigation = (
    direction: 'forward' | 'backward',
    isFutureSelectionBlocked: boolean
  ) => {
    if (selectedDate) {
      const newDate =
        direction === 'forward' ? subDays(selectedDate, -1) : subDays(selectedDate, 1);
      const today = new Date();

      if (!(isFutureSelectionBlocked && newDate > today)) {
        handleDateChange(newDate);
      }
    }
  };

  useEffect(() => {
    setIsCalendarOpen(isOpen);
  }, [isOpen]);

  return (
    <div className={twMerge('flex flex-col gap-3', wrapperClassName)}>
      {label && (
        <div>
          {typeof label === 'string' ? <Typography as='label'>{label}</Typography> : label}{' '}
          {isRequiredField && '*'}
        </div>
      )}
      <Dropdown
        open={isCalendarOpen}
        setOpen={setIsCalendarOpen}
        preventClick={true}
        className={containerClassName}
        content={
          <div
            className={`rounded-xl border border-${colorTypes.Pressed} bg-${colorTypes.White} drop-shadow-2xl`}
          >
            <CalendarRangePicker
              selectedDate={selectedDate}
              type={type}
              onChange={handleDateChange}
              isFutureSelectionLocked={isFutureSelectionLocked}
            />
          </div>
        }
      >
        <div className='flex gap-4 items-center'>
          {isNavigate && (
            <Button
              buttonVariant={'Icon'}
              buttonStyle={'Icon'}
              onClick={() => handleDateNavigation('backward', isFutureSelectionLocked)}
              className={`text-${colorTypes.Disabled} border rounded-default border-${colorTypes.Disabled} w-10 h-10 hover:text-${colorTypes.Blue}`}
            >
              <ArrowRightIcon className='rotate-180' />
            </Button>
          )}

          <div className={twMerge('min-w-[160px] w-full max-w-[200px]', className)}>
            <Input
              handleChange={handleInputChange}
              onClick={handleOnFocus}
              placeholder={placeholder ? placeholder : datepickerTranslations('placeholder.label')}
              inputVariant={'Date'}
              control={control}
              className={clsx(isCalendarOpen && `border-${colorTypes.Blue}`)}
              resetButton={false}
              readOnly={readOnly || permissions.isReadOnly}
              rightIcon={
                <CalendarIcon
                  className={`absolute right-2.5 top-1/2 transform -translate-y-1/2 text-${colorTypes.Blue}`}
                  onClick={handleOnFocus}
                />
              }
            />
          </div>
          {isNavigate && (
            <Button
              buttonVariant={'Icon'}
              buttonStyle={'Icon'}
              disabled={isToday && isFutureSelectionLocked}
              onClick={() => handleDateNavigation('forward', isFutureSelectionLocked)}
              className={twMerge(
                `text-${colorTypes.Disabled} border rounded-default border-${colorTypes.Disabled} hover:text-${colorTypes.Blue} w-10 h-10`,
                isToday &&
                  isFutureSelectionLocked &&
                  `bg-${colorTypes.HoverLight} border-${colorTypes.Hover}`
              )}
            >
              <ArrowRightIcon />
            </Button>
          )}
        </div>
      </Dropdown>
    </div>
  );
};
