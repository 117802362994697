import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITypeOfDisabilityForm } from '@/components/functions/MedicalInformation/Diagnostics/TypeOfDisability/helpers/useTypeOfDisabilityForm';
import { LoadingStatus } from '@/types/loadingStatus';

export interface ITypeOfDisabilityFetchPayload {
  personID: number;
}

export interface ITypeOfDisabilityUpdatePayload {
  personID: number;
  formValues: ITypeOfDisabilityForm;
}

export interface ITypeOfDisabilityImportPayload {
  personID: number;
}

export interface ITypeOfDisability {
  id: number;
  isDisabledPass: boolean;
  isUnlimited: boolean;
  disabilityType?: {
    id: number;
    value: string;
  } | null;
  disabilityPercentage?: {
    id: number;
    value: string;
  } | null;
  note?: string | null;
  emergencyInfo?: string | null;
  identityCardAuthorities?: {
    id: number;
    value: string;
  } | null;
  validDate?: string | null;
  referenceNumber?: string | null;
}
export interface ITypeOfDisabilityImportData {
  codes: {
    id: string;
    code?: string | null;
    description?: string | null;
  }[];
}

export interface IDiagnosticsTypeOfDisabilityState {
  typeOfDisability: ITypeOfDisability | null;
  typeOfDisabilityLock: LoadingStatus;
  fetchPayload: ITypeOfDisabilityFetchPayload | null;
  importPayload: ITypeOfDisabilityImportPayload | null;
  importData: ITypeOfDisabilityImportData | null;
  importDataLock: LoadingStatus;
  updatePayload: ITypeOfDisabilityUpdatePayload | null;
  updateLock: LoadingStatus;
}

const initialState: IDiagnosticsTypeOfDisabilityState = {
  typeOfDisability: null,
  typeOfDisabilityLock: LoadingStatus.NEVER,
  fetchPayload: null,
  importPayload: null,
  importData: null,
  importDataLock: LoadingStatus.NEVER,
  updatePayload: null,
  updateLock: LoadingStatus.NEVER,
};

export const diagnosticsTypeOfDisabilitySlice = createSlice({
  name: 'diagnosticsTypeOFDisability',
  initialState,
  reducers: {
    fetchTypeOfDisability: (state, action: PayloadAction<ITypeOfDisabilityFetchPayload>) => ({
      ...state,
      fetchPayload: action.payload,
    }),
    setTypeOfDisability: (state, action: PayloadAction<ITypeOfDisability | null>) => ({
      ...state,
      typeOfDisability: action.payload,
    }),
    setTypeOfDisabilityLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      typeOfDisabilityLock: action.payload,
    }),
    fetchTypeOfDisabilityImportData: (
      state,
      action: PayloadAction<ITypeOfDisabilityImportPayload>
    ) => ({
      ...state,
      importPayload: action.payload,
    }),
    setTypeOfDisabilityImportData: (
      state,
      action: PayloadAction<ITypeOfDisabilityImportData | null>
    ) => ({
      ...state,
      importData: action.payload,
    }),
    setTypeOfDisabilityImportDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      importDataLock: action.payload,
    }),
    updateTypeOfDisability: (state, action: PayloadAction<ITypeOfDisabilityUpdatePayload>) => ({
      ...state,
      updatePayload: action.payload,
    }),
    setUpdateTypeOfDisabilityLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateLock: action.payload,
    }),
  },
});

export const diagnosticsTypeOfDisabilityReducer = diagnosticsTypeOfDisabilitySlice.reducer;
export const diagnosticsTypeOfDisabilityActions = diagnosticsTypeOfDisabilitySlice.actions;
