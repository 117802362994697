import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { Button } from '@/components/Button/Button';
import { DashboardTempIcon } from '@/components/Icons/DashboardTempIcon';
import { MedicationAdministrationIcon } from '@/components/Icons/MedicationAdministrationIcon';
import { PersonIcon } from '@/components/Icons/PersonIcon';
import { ReportsIcon } from '@/components/Icons/ReportsIcon';
import { RollUpIcon } from '@/components/Icons/RollUpIcon';
import { MenuItem } from '@/components/LeftSidebar/MenuItem/MenuItem';
import {
  DASHBOARD_URL,
  MEDICATION_ADMINISTRATION_URL,
  PERSONS_URL,
  REPORTS_URL,
} from '@/core/constants/urls';
import { twMerge } from '@/core/utils/tailwindUtil';

export const LeftSidebar: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.button' });
  const [isOpen, setIsOpen] = useState(true);

  return (
    <motion.div
      className={twMerge('flex flex-col items-start h-full bg-white px-[10px]')}
      style={{ minWidth: isOpen ? '64px' : '200px' }}
      animate={{
        minWidth: isOpen ? '200px' : '64px',
        width: isOpen ? '200px' : '64px',
      }}
    >
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className={twMerge(isOpen ? 'self-end' : 'self-center', 'px-[10px] py-[20px]')}
        buttonStyle={'Icon'}
        buttonVariant={'Icon'}
      >
        <RollUpIcon isOpen={isOpen} />
      </Button>
      <MenuItem
        name={t('dashboard')}
        icon={<DashboardTempIcon />}
        link={DASHBOARD_URL}
        pattern={DASHBOARD_URL}
        isExpanded={isOpen}
      />
      <MenuItem
        name={t('persons')}
        icon={<PersonIcon />}
        link={PERSONS_URL}
        pattern={PERSONS_URL}
        isExpanded={isOpen}
      />
      <MenuItem
        name={t('medicationAdministration')}
        icon={<MedicationAdministrationIcon />}
        link={MEDICATION_ADMINISTRATION_URL}
        pattern={MEDICATION_ADMINISTRATION_URL}
        isExpanded={isOpen}
      />
      {process.env.REACT_APP_REPORTS_PAGE === 'true' && (
        <MenuItem
          name={t('reports')}
          icon={<ReportsIcon />}
          link={REPORTS_URL}
          pattern={REPORTS_URL}
          isExpanded={isOpen}
        />
      )}
    </motion.div>
  );
};
