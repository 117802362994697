import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from '@/core/redux/slices//app/appSlice';
import { codesICDReducer } from '@/core/redux/slices//functions/diagnostics/codesICD/codesICDSlice';
import { itemsICFReducer } from '@/core/redux/slices//functions/diagnostics/itemsICF/itemsICFSlice';
import { performanceProfileReducer } from '@/core/redux/slices//functions/performanceProfile/slice';
import { personDataReducers } from '@/core/redux/slices//functions/personGeneralData/personData/personDataSlice';
import { specialistGeneralDataReducers } from '@/core/redux/slices//functions/specialistGeneralData/generalData/specialistGeneralDataSlice';
import { diagnosticsModalsReducers } from '@/core/redux/slices//modalsSlice/functions/diagnostics/diagnosticsModalsSlice';
import { performanceProfileModalsReducers } from '@/core/redux/slices//modalsSlice/functions/performanceProfile/performancePrfoileModalSlice';
import { personGeneralDataModalsReducers } from '@/core/redux/slices//modalsSlice/functions/personGeneralData/personGeneralDataModalsSlice';
import { authReducer } from '@/core/redux/slices/auth/authSlice';
import { dashboardWidgetsReducer } from '@/core/redux/slices/dashboard/dashboardSlice';
import { dropdownItemsReducer } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import { efficacyAssessmentReducer } from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { functionPageReducer } from '@/core/redux/slices/functionPage/functionPageSlice';
import { activityPlanningAppointmentDetailsReducers } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { activityPlanningAppointmentParticipantsListReducers } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import { activityPlanningAppointmentsMeasuresListReducers } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/slice';
import { activityPlaningMeasuresListReducers } from '@/core/redux/slices/functions/activityPlanning/measuresList/slice';
import { checklistReducer } from '@/core/redux/slices/functions/checklist/checklistSlice';
import { developmentReportGoalsAndActivityReducer } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/slice';
import { developmentReportPersonDetailsReducer } from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/slice';
import { developmentReportReviewSupportReducer } from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/slice';
import { developmentReportVisionAndGoalsReducer } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/slice';
import { developmentReportReducer } from '@/core/redux/slices/functions/developmentReport/slice';
import { diagnosticsTypeOfDisabilityReducer } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/slice';
import { evaluateGoalsReducer } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/evaluateGoalsSlice';
import { participationPlanReducer } from '@/core/redux/slices/functions/fundingCycle/participationPlan/participationPlanSlice';
import { smartGoalsReducer } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { actionCourseReducers } from '@/core/redux/slices/functions/integrationPlan/actionCourse/actionCourseSlice';
import { competenceAnalysisReducers } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { generalDataReducers } from '@/core/redux/slices/functions/integrationPlan/generalData/generalDataSlice';
import { integrationPlanReducers } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { participantExpectationReducers } from '@/core/redux/slices/functions/integrationPlan/participantExpectation/participantExpectationSlice';
import { qualificationDocumentationReducers } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import { statusReportReducers } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { summaryFAReducers } from '@/core/redux/slices/functions/integrationPlan/summaryFA/summaryFASlice';
import { disabilityCharacteristicsReducer } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/slice';
import { diagnosticsSpecialFeaturesReducer } from '@/core/redux/slices/functions/medicalInformation/specialFeatures/slice';
import { medicationReducer } from '@/core/redux/slices/functions/medication/medicationSlice';
import { organizationalUnitsReducer } from '@/core/redux/slices/functions/organizationalUnits/slice';
import { addressesReducers } from '@/core/redux/slices/functions/personGeneralData/addresses/addressesSlice';
import { simpleSelfAssesmentReducer } from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { testingResultReducer } from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { vocationalTrainingAreaReducer } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { mobilityReducer } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { workingTimeReducer } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { logbookReducer } from '@/core/redux/slices/logbook/logbookSlice';
import { medicationAdministrationReducer } from '@/core/redux/slices/medicationAdministration/medicationAdministrationSlice';
import { commonModalsReducers } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { efficacyAssessmentModalReducer } from '@/core/redux/slices/modalsSlice/efficacyAssessment/efficacyAssessmentModalSlice';
import { activityPlaningAppointmentDetailsModalsReducer } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';
import { activityPlaningParticipantsModalsReducer } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/slice';
import { serialAppointmentModalsReducer } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/slice';
import { developmentReportVersionHistoryModalReducer } from '@/core/redux/slices/modalsSlice/functions/developmentReport/developmentReportVersionHistory/slice';
import { evaluateGoalsModalReducer } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/evaluateGoals/evaluateGoalsModalSlice';
import { participationPlanModalReducer } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/participationPlan/participationPlanModalSlice';
import { smartGoalsModalReducer } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';
import { integrationPlanModalsReducers } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { medicationModalsReducer } from '@/core/redux/slices/modalsSlice/functions/medication/medicationModalsSlice';
import { mobilityModalsReducers } from '@/core/redux/slices/modalsSlice/functions/mobility/slice';
import { organizationalUnitsModalsReducer } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { selfAssesmentModalsReducers } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selfAssesmentModalsSlice';
import { testingResultModalsReducer } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { vocationalTrainingAreaModalReducer } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { workingTimeMobilityModalsReducer } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/workingTimeMobilitModalsSlice';
import { functionsPageModalsReducer } from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { logbookModalsReducers } from '@/core/redux/slices/modalsSlice/logbook/logbookModalSlice';
import { medicationAdministrationModalsReducer } from '@/core/redux/slices/modalsSlice/medicationAdministration/medicationAdministrationModalSlice';
import { widgetsModalsReducers } from '@/core/redux/slices/modalsSlice/widgets/widgetsModalsSlice';
import { notificationsReducer } from '@/core/redux/slices/notifications/notificationsSlice';
import { parametersReducer } from '@/core/redux/slices/parameters/parametersSlice';
import { personPageReducer } from '@/core/redux/slices/personPage/personPageSlice';
import { reportsReducer } from '@/core/redux/slices/reports/reportsSlice';
import { userSettingsReducers } from '@/core/redux/slices/userSettings/userSettingsSlice';

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  userSettings: userSettingsReducers,
  notification: notificationsReducer,
  dashboard: dashboardWidgetsReducer,
  personPage: personPageReducer,
  functionPage: functionPageReducer,
  dropdownItems: dropdownItemsReducer,
  parameters: parametersReducer,
  medicationAdministration: medicationAdministrationReducer,
  reports: reportsReducer,
  logbook: logbookReducer,
  efficacyAssessment: efficacyAssessmentReducer,
  modals: combineReducers({
    commonModals: commonModalsReducers,
    widgetsModals: widgetsModalsReducers,
    functions: combineReducers({
      integrationPlanModals: integrationPlanModalsReducers,
      diagnosticsModals: diagnosticsModalsReducers,
      testingResultModals: testingResultModalsReducer,
      performanceProfileModals: performanceProfileModalsReducers,
      workingTimeMobilityModals: workingTimeMobilityModalsReducer,
      personGeneralDataModals: personGeneralDataModalsReducers,
      vocationalTrainingAreaModals: vocationalTrainingAreaModalReducer,
      medicationModals: medicationModalsReducer,
      selfAssesmentModals: selfAssesmentModalsReducers,
      organizationalUnitsModals: organizationalUnitsModalsReducer,
      fundingCycleModals: combineReducers({
        participationPlanModals: participationPlanModalReducer,
        smartGoalsModals: smartGoalsModalReducer,
        evaluateGoalsModals: evaluateGoalsModalReducer,
      }),
      activityPlaningModals: combineReducers({
        appointmentDetailsModals: activityPlaningAppointmentDetailsModalsReducer,
        participantsModals: activityPlaningParticipantsModalsReducer,
        serialAppointmentModals: serialAppointmentModalsReducer,
      }),
      mobilityModals: mobilityModalsReducers,
      developmentReportModals: combineReducers({
        developmentReportVersionHistoryModal: developmentReportVersionHistoryModalReducer,
      }),
    }),
    functionsPageModals: functionsPageModalsReducer,
    medicationAdministrationModals: medicationAdministrationModalsReducer,
    logbookModals: logbookModalsReducers,
    efficacyAssessmentModals: efficacyAssessmentModalReducer,
  }),
  functions: combineReducers({
    integrationPlan: combineReducers({
      integrationPlan: integrationPlanReducers,
      generalData: generalDataReducers,
      actionCourse: actionCourseReducers,
      competenceAnalysis: competenceAnalysisReducers,
      participantExpectation: participantExpectationReducers,
      summaryFA: summaryFAReducers,
      statusReport: statusReportReducers,
      qualificationDocumentation: qualificationDocumentationReducers,
    }),
    simpleSelfAssesment: simpleSelfAssesmentReducer,
    performanceProfile: performanceProfileReducer,
    workingTimeMobility: combineReducers({
      mobility: mobilityReducer,
      workingTime: workingTimeReducer,
    }),
    researchResult: testingResultReducer,
    specialistGeneralData: specialistGeneralDataReducers,
    personGeneralData: combineReducers({
      personData: personDataReducers,
      addresses: addressesReducers,
    }),
    diagnostics: combineReducers({
      codesICD: codesICDReducer,
      itemsICF: itemsICFReducer,
      typeOfDisability: diagnosticsTypeOfDisabilityReducer,
    }),
    vocationalTrainingArea: vocationalTrainingAreaReducer,
    medication: medicationReducer,
    organizationalUnits: organizationalUnitsReducer,
    activityPlanning: combineReducers({
      measuresList: activityPlaningMeasuresListReducers,
      measuresAppointmentsList: activityPlanningAppointmentsMeasuresListReducers,
      appointmentParticipantsList: activityPlanningAppointmentParticipantsListReducers,
      appointmentDetails: activityPlanningAppointmentDetailsReducers,
    }),
    fundingCycle: combineReducers({
      participationPlan: participationPlanReducer,
      smartGoals: smartGoalsReducer,
      evaluateGoals: evaluateGoalsReducer,
    }),
    developmentReport: combineReducers({
      developmentReport: developmentReportReducer,
      developmentReportPersonDetails: developmentReportPersonDetailsReducer,
      developmentReportVisionAndGoals: developmentReportVisionAndGoalsReducer,
      developmentReportReviewSupport: developmentReportReviewSupportReducer,
      developmentReportGoalsAndActivity: developmentReportGoalsAndActivityReducer,
    }),
    checklist: checklistReducer,
    medicalInformation: combineReducers({
      disabilityCharacteristics: disabilityCharacteristicsReducer,
      diagnosticsSpecialFeatures: diagnosticsSpecialFeaturesReducer,
    }),
  }),
});
