import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { AppliedTestProceduresList } from '@/components/functions/TestingResult/AppliedTestProcedures/AppliedTestProceduresList/AppliedTestProceduresList';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultSelectors } from '@/core/redux/slices/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';

export const AppliedTestProcedures: React.FC = () => {
  const { t: testProcedureResultTranslations } = useTranslation('testingResult', {
    keyPrefix: 'testProcedures',
  });
  const [selectedID, setSelectedID] = useState<number | null>(null);
  const appliedTestProcedures = useAppSelector(testingResultSelectors.appliedTestProcedures);

  const dispatch = useAppDispatch();

  const handleSelect = (id: number | null) => {
    setSelectedID((prev) => (prev === id ? null : id));
  };

  const handleProcedureDelete = () => {
    if (!selectedID) {
      return;
    }

    dispatch(testingResultModalsActions.openDeleteAppliedTestProcedureModal(selectedID));
  };

  const handleProcedureUpdate = () => {
    if (!selectedID) {
      return;
    }

    dispatch(testingResultModalsActions.openUpdateAppliedTestProcedureModal(selectedID));
  };

  const handleProcedureCreate = () => {
    dispatch(testingResultModalsActions.openCreateAppliedTestProcedureResultModal());
  };

  useEffect(() => {
    if (!appliedTestProcedures) {
      return;
    }

    setSelectedID(null);
  }, [appliedTestProcedures]);

  return (
    <div className={'flex flex-col gap-3 p-2'}>
      <Typography type={'H3'}>{testProcedureResultTranslations('title.label')}</Typography>
      <AppliedTestProceduresList
        handleSelect={handleSelect}
        appliedTestProcedures={appliedTestProcedures}
      />
      <div className={'px-3 pb-default flex flex-row justify-end'}>
        {selectedID !== null && (
          <div className={'flex flex-row mr-auto gap-2'}>
            <Button onClick={handleProcedureDelete}>
              {testProcedureResultTranslations('buttons.deleteTest.label')}
            </Button>
            <Button onClick={handleProcedureUpdate}>
              {testProcedureResultTranslations('buttons.changeTest.label')}
            </Button>
          </div>
        )}
        <Button className={'w-fit'} onClick={handleProcedureCreate}>
          {testProcedureResultTranslations('buttons.nextCompletedTest.label')}
        </Button>
      </div>
    </div>
  );
};
