import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '@/core/redux/hooks';
import { disabilityCharacteristicsSelectors } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/selectors';

export function useDisabilityCharacteristicsForm() {
  const form = useForm<Record<string, boolean>>();

  const disabilityCharacteristics = useAppSelector(
    disabilityCharacteristicsSelectors.disabilityCharacteristics
  );

  useEffect(() => {
    if (!disabilityCharacteristics) {
      return;
    }

    const newFormState = disabilityCharacteristics.reduce<Record<string, boolean>>(
      (accum, characteristic) => ({
        ...accum,
        [characteristic.id]: characteristic.isSelected,
      }),
      {}
    );

    form.reset(newFormState);
  }, [disabilityCharacteristics]);

  return form;
}
