import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { useDisabilityCharacteristicsForm } from '@/components/functions/MedicalInformation/DisabilityCharacteristics/helpers/useDisabilityCharacteristicsForm';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { disabilityCharacteristicsSelectors } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/selectors';
import { disabilityCharacteristicsActions } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const DisabilityCharacteristics: React.FC = () => {
  const { t: disabilityCharacteristicsTranslations } = useTranslation('disabilityCharacteristics');

  const disabilityCharacteristics = useAppSelector(
    disabilityCharacteristicsSelectors.disabilityCharacteristics
  );
  const disabilityCharacteristicsLock = useAppSelector(
    disabilityCharacteristicsSelectors.disabilityCharacteristicsLock
  );
  const updateLock = useAppSelector(disabilityCharacteristicsSelectors.updateLock);

  const { selectedPersonID } = useSelectedPerson();
  const dispatch = useAppDispatch();

  const form = useDisabilityCharacteristicsForm();

  const disabilityCharacteristicsForm = disabilityCharacteristics
    ? disabilityCharacteristics.map<FormItem | FormGroup>((characteristic) => ({
        className: 'flex flex-row gap-3',
        isReversed: true,
        name: String(characteristic.id),
        type: 'checkbox',
        label: characteristic.name || ``,
      }))
    : [];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      disabilityCharacteristicsActions.fetchDisabilityCharacteristics({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  const onSubmit = (data: Record<string, boolean>) => {
    if (!selectedPersonID) {
      return;
    }

    form.reset(data);

    dispatch(
      disabilityCharacteristicsActions.updateDisabilityCharacteristics({
        personID: selectedPersonID,
        formValues: data,
      })
    );
  };

  return (
    <div className={'flex flex-col h-full gap-3'}>
      <Typography type={'H3'}>{disabilityCharacteristicsTranslations('title.label')}</Typography>
      <Skeleton trigger={disabilityCharacteristicsLock === LoadingStatus.LOADING}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col h-full gap-3'}>
            <div className={'flex-1'}>
              <FormRender
                list={disabilityCharacteristicsForm}
                className={'grid grid-cols-5 gap-3 items-center'}
              />
            </div>
            {form.formState.isDirty && (
              <div className={'flex flex-row gap-3 justify-end'}>
                <Button type={'submit'}>
                  {updateLock === LoadingStatus.LOADING ? (
                    <SpinnerIcon className={'w-[24px] h-[24px]'} />
                  ) : (
                    disabilityCharacteristicsTranslations('buttons.save.label')
                  )}
                </Button>
                <Button buttonVariant={'Secondary'} onClick={() => form.reset()}>
                  {disabilityCharacteristicsTranslations('buttons.cancel.label')}
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </Skeleton>
    </div>
  );
};
