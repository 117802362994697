export enum DropdownItemsByFetch {
  SALUTATION = 'SALUTATION',
  MARITAL_STATUS = 'MARITAL_STATUS',
  GENDER = 'GENDER',
  SUPERVISOR = 'SUPERVISOR',
  HOUSING_FORM = 'HOUSING_FORM',
  EDUCATIONAL_SUPPORTER = 'EDUCATIONAL_SUPPORTER',
  SOCIAL_SERVICE_WORKER = 'SOCIAL_SERVICE_WORKER',
  MANAGER = 'MANAGER',
  FOREIGN_LEGAL_STATUS = 'FOREIGN_LEGAL_STATUS',
  NATIONALITY = 'NATIONALITY',
  CONFESSION = 'CONFESSION',
  HEALTH_INSURANCE = 'HEALTH_INSURANCE',
  JOBCOACH = 'JOBCOACH',
  GROUP_MANAGER = 'GROUP_MANAGER',
  SPECIALIST = 'SPECIALIST',
  TEST_PROCEDURE = 'TEST_PROCEDURE',
  TEST_PROCEDURE_PERFORMER = 'TEST_PROCEDURE_PERFORMER',
  DOSAGE_FORM = 'DOSAGE_FORM',
  MEASURE_LOCATION = 'MEASURE_LOCATION',
  APPOINTMENT_TYPE = 'APPOINTMENT_TYPE',
  DEMAND_MEDICATION_INDICATION = 'DEMAND_MEDICATION_INDICATION',
  DETERMINATION_METHOD = 'DETERMINATION_METHOD',
  SMART_GOAL_CHECK_ACHIEVEMENT_LEVEL = 'SMART_GOAL_CHECK_ACHIEVEMENT_LEVEL',
  SMART_GOAL_CHECK_ACTION = 'SMART_GOAL_CHECK_ACTION',
  DISABILITY_TYPE = 'DISABILITY_TYPE',
  DISABILITY_PERCENTAGE = 'DISABILITY_PERCENTAGE',
  IDENTITY_CARD_AUTHORITIES = 'IDENTITY_CARD_AUTHORITIES',
  CARE_LEVEL = 'CARE_LEVEL',
  WEEK_DAY = 'WEEK_DAY',
  APPOINTMENT_SERIAL_TYPE = 'APPOINTMENT_SERIAL_TYPE',
  MONTH = 'MONTH',
}
