import { twMerge } from 'tailwind-merge';

import { Typography } from '@/components/Typography/Typography';
import { ISmartGoalCompitience } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';

interface ICompitienceCard extends ISmartGoalCompitience {
  onCardClick: (compitencie: ISmartGoalCompitience) => void;
}

export const CompitenceCard: React.FC<ICompitienceCard> = ({
  onCardClick,
  id,
  name,
  hasChildren,
  measureAddedStatus,
}) => {
  return (
    <div
      className='w-[300px] bg-blue-light p-5 rounded-[10px] border border-solid border-stroke-light hover:bg-blue hover:text-white cursor-pointer'
      onClick={() => onCardClick({ id, name, hasChildren, measureAddedStatus })}
    >
      <div className='flex flex-col gap-5'>
        <Typography type='16-M' className='text-inherit'>
          {name}
        </Typography>
        <div className='relative'>
          <div className='w-full h-1 bg-stroke-light absolute'></div>
          <div className={twMerge('w-0 h-1 bg-orange absolute z-20', measureAddedStatus === 'NOT_ALL' && 'w-1/2')}></div>
          <div className={twMerge('w-0 h-1 bg-green absolute z-20', measureAddedStatus === 'ALL' && 'w-full')}></div>
        </div>
      </div>
    </div>
  );
};
