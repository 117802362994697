import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { qualificationDocumentationStatusEnum } from '@/core/enums/qualificationDocumentationStatusEnum';
import { useAppDispatch } from '@/core/redux/hooks';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';

interface IQualificationsDocumentationItem {
  documentName: string;
  documentID: string;
}

export const QualificationsDocumentationItem: React.FC<IQualificationsDocumentationItem> = ({
  documentName,
  documentID,
}) => {
  const { t: statusReportTranslations } = useTranslation('statusReport', {
    keyPrefix: 'notifications.unavailable',
  });
  const { control, watch } = useFormContext();

  const dispatch = useAppDispatch();

  const handleBlockedClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    value: string | number
  ): string => {
    event.preventDefault();
    dispatch(
      notificationsActions.showNotification({
        notification: {
          type: 'warn',
          title: statusReportTranslations('title.label'),
          description: statusReportTranslations('description.label'),
        },
      })
    );

    return String(value);
  };

  const anySelected =
    watch(`qualificationObjectivesDocumentations.${documentID}.selectedOption`) === '';

  const options: IRadioButtonGroupItem[] = [
    {
      value: qualificationDocumentationStatusEnum.isStarted,
      preventDefault: true,
      onClick: handleBlockedClick,
    },
    {
      value: qualificationDocumentationStatusEnum.isAchieved,
      preventDefault: true,
      onClick: handleBlockedClick,
    },
    {
      value: qualificationDocumentationStatusEnum.isNotStarted,
      preventDefault: !anySelected,
      onClick: !anySelected ? handleBlockedClick : undefined,
    },
  ];

  return (
    <div className={'grid grid-cols-12 gap-3 items-center'}>
      <Typography className={'col-span-5'}>{documentName}</Typography>
      <RadioButtonGroup
        options={options}
        name={`qualificationObjectivesDocumentations.${documentID}.selectedOption`}
        className={'col-span-3 grid grid-cols-3 items-center justify-items-center'}
      />
      <TextArea
        control={control.register(
          `qualificationObjectivesDocumentations.${documentID}.qualificationObjectiveStatusComment`
        )}
        containerClassName={'col-span-4'}
      />
    </div>
  );
};
