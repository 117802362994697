import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import {
  ITypeOfDisabilityForm,
  useTypeOfDisabilityForm,
} from '@/components/functions/MedicalInformation/Diagnostics/TypeOfDisability/helpers/useTypeOfDisabilityForm';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { diagnosticsTypeOfDisabilitySelectors } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/selectors';
import { diagnosticsTypeOfDisabilityActions } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IDisability {
  personID?: number | null;
}

export const TypeOfDisability: React.FC<IDisability> = ({ personID }) => {
  const { t: diagnosticsTypeOfDisabilityTranslations } = useTranslation('typeOfDisability');

  const form = useTypeOfDisabilityForm();

  const importLock = useAppSelector(
    diagnosticsTypeOfDisabilitySelectors.typeOfDisabilityImportDataLock
  );
  const typeOfDisabilityLock = useAppSelector(
    diagnosticsTypeOfDisabilitySelectors.typeOfDisabilityLock
  );
  const updateLock = useAppSelector(diagnosticsTypeOfDisabilitySelectors.updateLock);
  const dropDowns = useAppSelector(dropdownItemsSelectors.dropdownItems);

  const disabilityTypeOptions = dropDowns?.[DropdownItemsByFetch.DISABILITY_TYPE]
    ? dropDowns[DropdownItemsByFetch.DISABILITY_TYPE].map((option) => ({
        id: String(option.id),
        name: option.value,
      }))
    : [];

  const disabilityDegreeOptions = dropDowns?.[DropdownItemsByFetch.DISABILITY_PERCENTAGE]
    ? dropDowns[DropdownItemsByFetch.DISABILITY_PERCENTAGE].map((option) => ({
        id: String(option.id),
        name: option.value,
      }))
    : [];

  const identityCardAuthoritiesOptions = dropDowns?.[DropdownItemsByFetch.IDENTITY_CARD_AUTHORITIES]
    ? dropDowns[DropdownItemsByFetch.IDENTITY_CARD_AUTHORITIES].map((option) => ({
        id: String(option.id),
        name: option.value,
      }))
    : [];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      diagnosticsTypeOfDisabilityActions.fetchTypeOfDisability({
        personID: personID,
      })
    );
  }, [personID]);

  const isDisabled = form.watch('isDisabledPass');

  const typeOfDisabilityForm: (
    | FormItem<ITypeOfDisabilityForm>
    | FormGroup<ITypeOfDisabilityForm>
  )[] = [
    {
      className: 'w-full',
      fieldsContainerClassName: 'grid grid-cols-2 gap-3',
      fields: [
        {
          type: 'select',
          options: disabilityTypeOptions,
          name: 'disabilityTypeID',
          label: diagnosticsTypeOfDisabilityTranslations('fields.disabilityType.label'),
        },
        {
          type: 'select',
          options: disabilityDegreeOptions,
          name: 'disabilityPercentageID',
          label: diagnosticsTypeOfDisabilityTranslations('fields.disabilityDegree.label'),
        },
      ],
    },
    {
      className: 'w-full',
      fieldsContainerClassName: 'grid grid-cols-2 gap-3',
      fields: [
        {
          type: 'textarea',
          name: 'note',
          label: diagnosticsTypeOfDisabilityTranslations('fields.note.label'),
        },
        {
          type: 'textarea',
          name: 'emergencyInfo',
          label: diagnosticsTypeOfDisabilityTranslations('fields.emergencyInfo.label'),
        },
      ],
    },
    {
      className: 'flex-row',
      type: 'checkbox',
      name: 'isDisabledPass',
      label: diagnosticsTypeOfDisabilityTranslations('fields.disabilityDegree.label'),
    },
    {
      condition: isDisabled,
      className: 'w-full',
      fieldsContainerClassName: 'grid grid-cols-2 gap-3',
      fields: [
        {
          fieldsContainerClassName: 'grid grid-cols-2 gap-3',
          fields: [
            {
              className: 'flex-row',
              type: 'checkbox',
              name: 'isUnlimited',
              label: diagnosticsTypeOfDisabilityTranslations('fields.isUnlimited.label'),
            },
            {
              type: 'datepicker',
              name: 'validDate',
              label: diagnosticsTypeOfDisabilityTranslations('fields.validDate.label'),
            },
          ],
        },
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              type: 'select',
              options: identityCardAuthoritiesOptions,
              name: 'identityCardAuthoritiesID',
              label: diagnosticsTypeOfDisabilityTranslations(
                'fields.identityCardAuthorities.label'
              ),
            },
            {
              type: 'textarea',
              options: [],
              name: 'referenceNumber',
              label: diagnosticsTypeOfDisabilityTranslations('fields.referenceNumber.label'),
            },
          ],
        },
      ],
    },
  ];

  const handleImportData = () => {
    if (!personID) {
      return;
    }

    dispatch(
      diagnosticsTypeOfDisabilityActions.fetchTypeOfDisabilityImportData({
        personID: personID,
      })
    );
  };

  const onSubmit = (data: ITypeOfDisabilityForm) => {
    if (!personID) {
      return;
    }

    form.reset(data);

    dispatch(
      diagnosticsTypeOfDisabilityActions.updateTypeOfDisability({
        personID: personID,
        formValues: data,
      })
    );
  };

  return (
    <div className={'h-full flex flex-col gap-3'}>
      <Skeleton trigger={typeOfDisabilityLock === LoadingStatus.LOADING}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='h-full flex flex-col gap-4'>
            <div className={'flex-1 overflow-y-auto'}>
              <FormRender
                list={typeOfDisabilityForm}
                translationContext={diagnosticsTypeOfDisabilityTranslations}
              />
            </div>
            <div className={'flex flex-row gap-3 justify-between'}>
              <Button onClick={handleImportData}>
                {importLock === LoadingStatus.LOADING ? (
                  <SpinnerIcon className={'w-[24px] h-[24px]'} />
                ) : (
                  diagnosticsTypeOfDisabilityTranslations('buttons.import.label')
                )}
              </Button>
              {form.formState.isDirty && (
                <div className={'flex flex-row gap-3'}>
                  <Button type={'submit'}>
                    {updateLock === LoadingStatus.LOADING ? (
                      <SpinnerIcon className={'w-[24px] h-[24px]'} />
                    ) : (
                      diagnosticsTypeOfDisabilityTranslations('buttons.save.label')
                    )}
                  </Button>
                  <Button onClick={() => form.reset()} buttonVariant={'Secondary'}>
                    {diagnosticsTypeOfDisabilityTranslations('buttons.cancel.label')}
                  </Button>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </Skeleton>
    </div>
  );
};
