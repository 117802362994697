import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { useMobilityForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/hooks/useMobilityForm';
import { CarServiceForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/MobilityForms/CarServiceForm';
import { JobTicketForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/MobilityForms/JobTicketForm';
import { SelfDriverForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/EditMobilityModal/MobilityForms/SelfDriverForm';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ToggleGroup } from '@/components/ToggleGroup/ToggleGroup';
import { IToggleGroupItem } from '@/components/ToggleGroup/ToggleGroupItem';
import { Typography } from '@/components/Typography/Typography';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { mobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/mobility/selectors';
import { mobilityModalsActions } from '@/core/redux/slices/modalsSlice/functions/mobility/slice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

export const EditMobilityModal: React.FC = () => {
  const { isRender } = useAppSelector(mobilityModalsSelectors.editMobilityModal);

  return isRender ? <EditMobilityModalContent /> : null;
};

export const EditMobilityModalContent: React.FC = () => {
  const { t: mobilityTranslations } = useTranslation('mobility');

  const { payload, mobilityDetailsLock, mobilityDetails, updateMobilityLock } = useAppSelector(
    mobilityModalsSelectors.editMobilityModal
  );

  const dispatch = useAppDispatch();

  const { form, selectedMobility, handleSubmit } = useMobilityForm();
  const handleClose = () => {
    dispatch(mobilityModalsActions.closeEditMobilityModal());
  };

  const mobilityTypesValues = mobilityDetails?.availableMobilityTypes.map<ISelectItem>(
    (mobilityType) => ({
      id: String(mobilityType.id),
      component: mobilityType.name,
    })
  );

  const workingDaysOptions = mobilityDetails?.availableWorkingDays.map<IToggleGroupItem>(
    (workingDay) => ({
      id: workingDay.id,
      value: workingDay.name?.substring(0, 3),
      readOnly: !workingDay.isWorkingDay,
    })
  );

  const handleMobilityTypeSelect = (id: string) => {
    const foundElement = mobilityDetails?.availableMobilityTypes.find(
      (mobilityType) => mobilityType.id === Number(id)
    );

    if (!foundElement) {
      return;
    }

    form.setValue('mobilityType', foundElement);
  };

  const renderForm = () => {
    if (!payload || !payload.context) {
      return;
    }

    switch (selectedMobility?.type) {
      case MobilityFormTypes.CarService:
        return <CarServiceForm />;
      case MobilityFormTypes.SelfDriver:
        return <SelfDriverForm />;
      case MobilityFormTypes.JobTicket:
        return <JobTicketForm />;
      default:
        return <Typography>{mobilityTranslations('placeholders.notImplemented.label')}</Typography>;
    }
  };

  useEffect(() => {
    if (!payload) {
      return;
    }

    dispatch(mobilityModalsActions.fetchMobilityDetails(payload));
  }, [payload]);

  useEffect(() => {
    if (updateMobilityLock === LoadingStatus.LOADED) {
      handleClose();
    }
  }, [updateMobilityLock]);

  const onSubmit = () => {
    form.trigger().then((isValid) => {
      if (isValid) {
        handleSubmit();
      }
    });
  };

  const footer = (
    <div className={'flex gap-[10px]'}>
      <Button className={'w-full'} onClick={onSubmit}>
        {updateMobilityLock === LoadingStatus.LOADING ? (
          <SpinnerIcon className={`text-${colorTypes.White} w-[24px] h-[24px]`} />
        ) : (
          mobilityTranslations('buttons.save.label')
        )}
      </Button>
      <Button buttonVariant={'Secondary'} className={'w-full'} onClick={handleClose}>
        {mobilityTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar position={'right'} onExit={handleClose} className={'min-w-[700px]'} footer={footer}>
      <div className={'flex flex-col gap-[30px] p-5'}>
        <Typography type={'H2'}>
          {payload?.mobilityDay?.name
            ? mobilityTranslations(`header.${payload.context}.withDay.label` as any, {
                day: mobilityTranslations(
                  `header.weekDays.${payload.mobilityDay.name}.label` as any
                ),
              })
            : mobilityTranslations(`header.${payload?.context}.label` as any)}
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className={'flex flex-col gap-[30px]'}>
            <Skeleton trigger={mobilityDetailsLock === LoadingStatus.LOADING}>
              <SelectForm
                control={form.register('mobilityType.id')}
                values={mobilityTypesValues}
                onChange={handleMobilityTypeSelect}
                label={mobilityTranslations(
                  payload?.context === 'arrival'
                    ? 'forms.mobilityArrivalType.label'
                    : 'forms.mobilityDepartureType.label'
                )}
              />
              {renderForm()}
              <ToggleGroup
                control={form.register('appliedDays')}
                options={workingDaysOptions}
                label={mobilityTranslations('forms.availableWorkingDays.label')}
              />
            </Skeleton>
          </form>
        </FormProvider>
      </div>
    </Sidebar>
  );
};
