import { TFunction } from 'i18next';
import { ObjectSchema } from 'yup';

import { ICompleteMeasureForm } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/useCompleteMeasureModalForm';
import { yup } from '@/core/utils/commonUtils';

export const completeMeasureFormSchema = (
  isMnTerminWirksamkeitPflicht: boolean,
  translations: TFunction<'completeMeasureModal', undefined>
): ObjectSchema<ICompleteMeasureForm> => {
  const measureItemSchema = yup.object().shape({
    id: yup.number().required(),
    isParticipated: yup.boolean().required(),
    isUnderDirection: yup.boolean().required(),
    isAlmostIndependent: yup.boolean().required(),
    isIndependent: yup.boolean().required(),
    comment: yup
      .string()
      .nullable()
      .test(
        'comment-required',
        translations('measureTable.errors.commentRequired.label'),
        function (value) {
          const { isParticipated, isUnderDirection, isAlmostIndependent, isIndependent } =
            this.parent;
          const anyFlagTrue =
            isParticipated || isUnderDirection || isAlmostIndependent || isIndependent;
          return isMnTerminWirksamkeitPflicht || anyFlagTrue ? Boolean(value) : true;
        }
      ),
  });

  const appointmentItemSchema = yup.object().shape({
    id: yup.number().required(),
    isParticipated: yup.boolean().required(),
    isUnderDirection: yup.boolean().required(),
    isAlmostIndependent: yup.boolean().required(),
    isIndependent: yup.boolean().required(),
    comment: yup.string().nullable(),
  });

  const groupSchema = yup.object().shape({
    id: yup.number().required(),
    items: yup
      .array()
      .of(measureItemSchema)
      .test(
        'flag-comment-validation',
        translations('measureTable.errors.reviewRequired.label'),
        function (items) {
          if (!items) return true;
          const hasComment = items.some((item) => item.comment);
          const anyFlagTrue = items.some(
            (item) =>
              item.isParticipated ||
              item.isUnderDirection ||
              item.isAlmostIndependent ||
              item.isIndependent
          );
          return !hasComment || (hasComment && anyFlagTrue);
        }
      ),
  });

  const rootGroupSchema = yup.object().shape({
    id: yup.number().required(),
    groups: yup.array().of(groupSchema),
  });

  return yup.object().shape({
    rootGroups: yup.array().of(rootGroupSchema),
    selectedMeasure: yup.string().optional(),
    appointments: yup.array().of(appointmentItemSchema),
  });
};
