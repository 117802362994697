import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { EditTextIcon } from '@/components/Icons/EditTextIcon';
import { EmptyGoalsIcon } from '@/components/Icons/EmptyGoalsIcon';
import { PlusCircleIcon } from '@/components/Icons/PlusCircleIcon';
import { TrashIcon } from '@/components/Icons/TrashIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { FundingCycleSection } from '../../FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '../../FundingCycleSectionHeader/FundingCycleSectionHeader';

import styles from './../../styles.module.scss';

interface IGoalsSection {
  isControled?: boolean;
  className?: string;
}

export const GoalsSection: React.FC<IGoalsSection> = ({ isControled = false, className }) => {
  const { t: smartGoalsTranslations } = useTranslation('smartGoals');

  const dispatch = useAppDispatch();

  const [isShowCompleted, setIsShowCompleted] = useState(false);

  const data = useAppSelector(smartGoalsSelectors.smartGoals);
  const smartGoalsLock = useAppSelector(smartGoalsSelectors.smartGoalsLock);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);

  const isLoading = smartGoalsLock === LoadingStatus.LOADING;
  const isEmptyData = !data || !data.length;

  const { selectedPersonID } = useSelectedPerson();

  const renderEmptyGoals = useCallback(() => {
    return (
      <div className='flex flex-col gap-1 items-center justify-center h-full'>
        <EmptyGoalsIcon />
        <Typography>
          {' '}
          {!isShowCompleted
            ? smartGoalsTranslations('table.empty.label')
            : smartGoalsTranslations('table.emptyCompleted.label')}
        </Typography>
      </div>
    );
  }, [isShowCompleted]);

  const openDisplaySmartGoalsModal = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsModalActions.openDisplaySmartGoalModal({
        smartGoalID: id,
        personID: selectedPersonID,
      })
    );
  };

  const openAddMeasuresModal = (id: number) => {
    dispatch(
      smartGoalsModalActions.openAddMeasuresModal({
        smartGoalID: id,
      })
    );
  };

  const handleDeleteSmartGoal = (id: number) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsModalActions.openConfirmDeleteSmartGoalModal({
        smartGoalID: id,
        personID: selectedPersonID,
      })
    );
  };

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      smartGoalsActions.fetchSmartGoals({
        personID: selectedPersonID,
        isClosed: isShowCompleted,
      })
    );
  }, [isShowCompleted]);

  const columns: ITableColumn[] = [
    {
      header: smartGoalsTranslations('columns.name.label'),
      accessor: 'name',
      className: 'w-full  max-w-[438px] whitespace-normal py-3 px-5 pl-6',
    },
    {
      header: smartGoalsTranslations('columns.agreedOnTimestamp.label'),
      accessor: 'agreedOnDate',
      className: 'py-3 px-5',
    },
    {
      header: smartGoalsTranslations('columns.agreedBy.label'),
      accessor: 'agreedBy',
      className: 'py-3 px-5',
    },
    {
      header: smartGoalsTranslations('columns.nextReviewTimestamp.label'),
      accessor: 'nextReviewDate',
      className: 'py-3 px-5',
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      className: 'py-1 px-5 pr-6',
      template: (_, row) => {
        return (
          <div className={'flex flex-row items-center'}>
            {/* <>
              <Button
                data-tooltip-id='completeGoal'
                data-tooltip-content={'Complete goal'}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={`text-${colorTypes.Grey} p-2 hover:bg-stroke rounded-md`}
                onClick={(e) => {
                  e.stopPropagation();
                  openAddMeasuresModal(row.id);
                }}
              >
                <PlusCircleIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'addMeasure'} style={{ zIndex: 100000 }} />
            </> */}
            <>
              <Button
                data-tooltip-id='addMeasure'
                data-tooltip-content={smartGoalsTranslations(
                  'columns.actions.addMeasure.tooltip.label'
                )}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={`text-${colorTypes.Grey} p-2 hover:bg-stroke rounded-md`}
                onClick={(e) => {
                  e.stopPropagation();
                  openAddMeasuresModal(row.id);
                }}
              >
                <PlusCircleIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'addMeasure'} style={{ zIndex: 100000 }} />
            </>

            <>
              <Button
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={`text-${colorTypes.Grey}  p-2 hover:bg-stroke rounded-md`}
                onClick={(e) => {
                  e.stopPropagation();
                  openDisplaySmartGoalsModal(row.id);
                }}
                data-tooltip-id='updateGoal'
                data-tooltip-content={smartGoalsTranslations(
                  'columns.actions.updateGoal.tooltip.label'
                )}
              >
                <EditTextIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'updateGoal'} style={{ zIndex: 100000 }} />
            </>

            <>
              <Button
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={`text-${colorTypes.Grey}  p-2 hover:bg-stroke hover:text-error rounded-md`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteSmartGoal(row.id);
                }}
                data-tooltip-id='deleteGoal'
                data-tooltip-content={smartGoalsTranslations(
                  'columns.actions.deleteGoal.tooltip.label'
                )}
              >
                <TrashIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'deleteGoal'} style={{ zIndex: 100000 }} />
            </>
          </div>
        );
      },
    },
  ];

  const goalsControls = (
    <Button
      onClick={() => openDisplaySmartGoalsModal(0)}
      className='text-sm h-9 px-5 py-2.5 leading-[140%]'
    >
      {smartGoalsTranslations('buttons.addGoal.label')}
    </Button>
  );

  const goalsTitle = (
    <div className='flex gap-5 items-center h-full'>
      <Typography type={'H3'}>{smartGoalsTranslations('smartGoals.label')}</Typography>
      <div className='w-[1px] bg-stroke h-[28px]'></div>
      <div
        className='flex gap-2.5 cursor-pointer'
        onClick={() => {
          setIsShowCompleted(!isShowCompleted);
        }}
      >
        <CheckboxUI checked={isShowCompleted} />
        <Typography>{smartGoalsTranslations('filters.isCompleted.label')}</Typography>
      </div>
    </div>
  );

  return (
    <FundingCycleSection
      className={className}
      header={
        <FundingCycleSectionHeader title={goalsTitle} controls={isControled && goalsControls} />
      }
    >
      {!isEmptyData && (
        <Table
          data={data}
          columns={columns}
          singleSelect
          initialSelectedState={[selectedSmartGoalID]}
          onSingleSelectChange={(selected) =>
            dispatch(smartGoalsActions.setSelectedSmartGoal(selected))
          }
          selectedRowClassName={styles.row_active}
          rowClassName={styles.row}
        />
      )}
      {!isLoading && isEmptyData && renderEmptyGoals()}
    </FundingCycleSection>
  );
};
