import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { FundingCycleSection } from '@/components/functions/FundingCycle/FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '@/components/functions/FundingCycle/FundingCycleSectionHeader/FundingCycleSectionHeader';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { evaluateGoalsActions } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/evaluateGoalsSlice';
import { evaluateGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/selectors';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { evaluateGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/evaluateGoals/evaluateGoalsModalSlice';

import { GoalsSection } from '../SmartGoals/GoalsSection/GoalsSection';

import { GoalCheckSection } from './GoalCheckSection/GoalCheckSection';

export const EvaluateGoals: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: evaluateGoalsTranslations } = useTranslation('evaluateGoals');
  const { t: smartGoalsTranslations } = useTranslation('smartGoals');

  const goalChecks = useAppSelector(evaluateGoalsSelectors.goalChecks);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);
  const selectedGoalCheckID = useAppSelector(evaluateGoalsSelectors.selectedGoalCheck);

  const selectedGoalCheck = useMemo(
    () => goalChecks.find((item) => item.id === selectedGoalCheckID),
    [selectedGoalCheckID]
  );

  const openDisplayGoalCheckModal = (id: number) => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsModalActions.openDisplayGoalCheckModal({
        smartGoalID: selectedSmartGoalID,
        goalCheckID: id,
      })
    );
  };

  const handleDeleteGoalCheck = (id: number) => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      evaluateGoalsActions.deleteGoalCheck({
        smartGoalID: selectedSmartGoalID,
        goalCheckID: id,
      })
    );
  };

  const goalsCheckControls = selectedSmartGoalID ? (
    <div className={'flex flex-row gap-2'}>
      <Button
        onClick={() => openDisplayGoalCheckModal(0)}
        className='text-sm h-9 px-5 py-2.5 leading-[140%]'
      >
        {evaluateGoalsTranslations('buttons.addCheck.label')}
      </Button>
      {selectedGoalCheck && (
        <>
          <Button
            onClick={() => openDisplayGoalCheckModal(selectedGoalCheck.id)}
            className='text-sm h-9 px-5 py-2.5 leading-[140%]'
          >
            {evaluateGoalsTranslations('buttons.editCheck.label')}
          </Button>
          {selectedGoalCheck.canBeDeleted && (
            <Button
              onClick={() => handleDeleteGoalCheck(selectedGoalCheck.id)}
              className='text-sm h-9 px-5 py-2.5 leading-[140%]'
            >
              {evaluateGoalsTranslations('buttons.deleteCheck.label')}
            </Button>
          )}
        </>
      )}
    </div>
  ) : null;

  return (
    <div className='h-full flex flex-col gap-4 py-5 px-8'>
      <GoalsSection className='basis-6/12 flex-grow' />
      <FundingCycleSection
        className='basis-6/12 flex-grow'
        header={
          <FundingCycleSectionHeader
            title={evaluateGoalsTranslations('goalChecks.label')}
            controls={goalsCheckControls}
          />
        }
      >
        {selectedSmartGoalID && <GoalCheckSection />}
      </FundingCycleSection>
    </div>
  );
};
