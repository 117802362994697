import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CompleteMeasureAppointmentList } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureAppointmentList/CompleteMeasureAppointmentList';
import { CompleteMeasureAppointmentTableHeader } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureAppointmentList/CompleteMeasureAppointmentTableHeader';
import { CompleteMeasureRootGroupList } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureList/CompleteMeasureRootGroupList';
import { CompleteMeasureTableHeader } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/CompleteMeasureList/CompleteMeasureTableHeader';
import { useCompleteMeasureModalForm } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/CompleteMeasureModal/CompleteMeasureModalContent/hooks/useCompleteMeasureModalForm';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { getFirstFormError } from '@/core/utils/formUtils';
import { LoadingStatus } from '@/types/loadingStatus';

export const CompleteMeasureModalContent: React.FC = () => {
  const { t: completeMeasureModalTranslations } = useTranslation('completeMeasureModal');

  const { payload, measureDetailLock } = useAppSelector(
    vocationalTrainingAreaModalSelectors.completeMeasureModal
  );

  const dispatch = useAppDispatch();

  const { form, handleCloseMeasure } = useCompleteMeasureModalForm();

  const handleExit = () => {
    dispatch(vocationalTrainingAreaModalActions.closeCompleteMeasureModal());
  };

  useEffect(() => {
    if (!payload) {
      return;
    }

    dispatch(vocationalTrainingAreaModalActions.fetchCompleteMeasureModalDetails(payload));
  }, [payload]);

  const firstError = getFirstFormError(form.formState.errors);

  return (
    <Sidebar onExit={handleExit} position={'right'} className={'max-w-[75vw]'}>
      <div className={'flex flex-col gap-5 min-w-[30vw] items-stretch p-5 pb-0 h-full'}>
        <Typography type={'H3'}>{completeMeasureModalTranslations('title.label')}</Typography>
        <Skeleton trigger={measureDetailLock === LoadingStatus.LOADING}>
          <FormProvider {...form}>
            <CompleteMeasureTableHeader />
            <CompleteMeasureRootGroupList />
            <div className={'w-full flex flex-row gap-3 justify-end items-center'}>
              <Typography color={'Error'}>{firstError}</Typography>
              <Button className={'self-end w-fit'} onClick={handleCloseMeasure}>
                {completeMeasureModalTranslations('measureTable.buttons.confirm.label')}
              </Button>
            </div>
            <Typography type={'H3'}>
              {completeMeasureModalTranslations('appointmentTable.title.label')}
            </Typography>
            <CompleteMeasureAppointmentTableHeader />
            <CompleteMeasureAppointmentList />
          </FormProvider>
        </Skeleton>
      </div>
    </Sidebar>
  );
};
