import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import FormRender, { FormGroup, FormItem } from '@/components/FormRender/FormRender';
import { useDiagnosticsSpecialFeatures } from '@/components/functions/MedicalInformation/SpecialFeatures/helpers/useDiagnosticsSpecialFeatures';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { diagnosticsSpecialFeaturesSelectors } from '@/core/redux/slices/functions/medicalInformation/specialFeatures/selectors';
import {
  diagnosticsSpecialFeaturesActions,
  IDiagnosticsSpecialFeatures,
} from '@/core/redux/slices/functions/medicalInformation/specialFeatures/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const SpecialFeatures: React.FC = () => {
  const { t: diagnosticsSpecialFeaturesTranslations } = useTranslation(
    'diagnosticsSpecialFeatures'
  );

  const { selectedPersonID } = useSelectedPerson();
  const updateLock = useAppSelector(diagnosticsSpecialFeaturesSelectors.updateLock);
  const diagnosticsSpecialFeaturesLock = useAppSelector(
    diagnosticsSpecialFeaturesSelectors.diagnosticsSpecialFeaturesLock
  );
  const dropDowns = useAppSelector(dropdownItemsSelectors.dropdownItems);

  const careLevelOptions = dropDowns?.[DropdownItemsByFetch.CARE_LEVEL]
    ? dropDowns[DropdownItemsByFetch.CARE_LEVEL].map((item) => ({
        id: item.id,
        name: item.value,
      }))
    : [];

  const dispatch = useAppDispatch();

  const form = useDiagnosticsSpecialFeatures();

  const isDiabetic = form.watch('isDiabetic');
  const isAllergic = form.watch('isAllergic');
  const isAsthmatic = form.watch('isAsthmatic');
  const isSeizureDisorder = form.watch('isSeizureDisorder');
  const isHeartDisease = form.watch('isHeartDisease');
  const isOther = form.watch('isOther');

  const diagnosticsSpecialFeaturesForm: (
    | FormItem<IDiagnosticsSpecialFeatures>
    | FormGroup<IDiagnosticsSpecialFeatures>
  )[] = [
    {
      name: 'careLevelID',
      type: 'select',
      options: careLevelOptions,
    },
    {
      className: 'w-full',
      fieldsContainerClassName: 'grid grid-cols-2 gap-3',
      fields: [
        //Diabetic
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              className: 'flex flex-row gap-3',
              isReversed: true,
              name: 'isDiabetic',
              type: 'checkbox',
              label: diagnosticsSpecialFeaturesTranslations('fields.isDiabetic.label'),
            },
            {
              condition: isDiabetic,
              fieldsContainerClassName: 'border flex flex-col gap-3 p-3',
              fields: [
                {
                  className: 'flex flex-row',
                  isReversed: true,
                  name: 'isTabletsRequired',
                  type: 'checkbox',
                  label: diagnosticsSpecialFeaturesTranslations('fields.isTabletsRequired.label'),
                },
                {
                  className: 'flex flex-row',
                  isReversed: true,
                  name: 'isInsulinRequired',
                  type: 'checkbox',
                  label: diagnosticsSpecialFeaturesTranslations('fields.isInsulinRequired.label'),
                },
                {
                  className: 'flex-1',
                  name: 'diabeticNote',
                  type: 'textarea',
                },
              ],
            },
          ],
        },
        //Seizure
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              className: 'flex flex-row gap-3',
              isReversed: true,
              name: 'isSeizureDisorder',
              type: 'checkbox',
              label: diagnosticsSpecialFeaturesTranslations('fields.isSeizureDisorder.label'),
            },
            {
              condition: isSeizureDisorder,
              fieldsContainerClassName: 'border flex flex-col gap-3 p-3',
              fields: [
                {
                  name: 'seizureDisorderForm',
                  type: 'input',
                  label: diagnosticsSpecialFeaturesTranslations('fields.seizureDisorderForm.label'),
                },
                {
                  className: 'justify-end',
                  name: 'lastSeizureDate',
                  type: 'datepicker',
                  label: diagnosticsSpecialFeaturesTranslations('fields.lastSeizureDate.label'),
                },
                {
                  name: 'seizureDisorderNote',
                  type: 'textarea',
                },
              ],
            },
          ],
        },
        //Allergy
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              className: 'flex flex-row gap-3',
              isReversed: true,
              name: 'isAllergic',
              type: 'checkbox',
              label: diagnosticsSpecialFeaturesTranslations('fields.isAllergic.label'),
            },
            {
              condition: isAllergic,
              fieldsContainerClassName: 'border flex flex-col gap-3 p-3',
              fields: [
                {
                  name: 'hasAllergyPassport',
                  type: 'checkbox',
                  label: diagnosticsSpecialFeaturesTranslations('fields.hasAllergyPassport.label'),
                },
                {
                  name: 'allergicNote',
                  type: 'textarea',
                },
              ],
            },
          ],
        },
        //Asthma
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              className: 'flex flex-row gap-3',
              isReversed: true,
              name: 'isAsthmatic',
              type: 'checkbox',
              label: diagnosticsSpecialFeaturesTranslations('fields.isAsthmatic.label'),
            },
            {
              condition: isAsthmatic,
              fieldsContainerClassName: 'border flex flex-col gap-3 p-3',
              fields: [
                {
                  name: 'isEmergencyMedication',
                  type: 'checkbox',
                  label: diagnosticsSpecialFeaturesTranslations(
                    'fields.isEmergencyMedication.label'
                  ),
                },
                {
                  name: 'asthmaticNote',
                  type: 'textarea',
                },
              ],
            },
          ],
        },

        //Heart Disease
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              className: 'flex flex-row gap-3',
              isReversed: true,
              name: 'isHeartDisease',
              type: 'checkbox',
              label: diagnosticsSpecialFeaturesTranslations('fields.isHeartDisease.label'),
            },
            {
              condition: isHeartDisease,
              fieldsContainerClassName: 'border flex flex-col gap-3 p-3',
              fields: [
                {
                  name: 'heartDiseaseNote',
                  type: 'textarea',
                },
              ],
            },
          ],
        },

        //Other
        {
          fieldsContainerClassName: 'flex flex-col gap-3',
          fields: [
            {
              className: 'flex flex-row gap-3',
              isReversed: true,
              name: 'isOther',
              type: 'checkbox',
              label: diagnosticsSpecialFeaturesTranslations('fields.isOther.label'),
            },
            {
              condition: isOther,
              fieldsContainerClassName: 'border flex flex-col gap-3 p-3',
              fields: [
                {
                  name: 'otherNote',
                  type: 'textarea',
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      diagnosticsSpecialFeaturesActions.fetchDiagnosticsSpecialFeatures({
        personID: selectedPersonID,
      })
    );
  }, [selectedPersonID]);

  const onSubmit = (data: IDiagnosticsSpecialFeatures) => {
    if (!selectedPersonID) {
      return;
    }

    form.reset(data);

    dispatch(
      diagnosticsSpecialFeaturesActions.updateDiagnosticsSpecialFeatures({
        personID: selectedPersonID,
        formValues: data,
      })
    );
  };

  return (
    <div className={'flex flex-col gap-3 h-full'}>
      <Skeleton trigger={diagnosticsSpecialFeaturesLock === LoadingStatus.LOADING}>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className={'flex flex-col gap-3 h-full overflow-hidden'}
          >
            <div className={'flex-1 overflow-y-auto'}>
              <FormRender list={diagnosticsSpecialFeaturesForm} />
            </div>
            {form.formState.isDirty && (
              <div className={'flex flex-row gap-3 justify-end'}>
                <Button type={'submit'}>
                  {updateLock === LoadingStatus.LOADING ? (
                    <SpinnerIcon className={'w-[24px] h-[24px]'} />
                  ) : (
                    diagnosticsSpecialFeaturesTranslations('buttons.save.label')
                  )}
                </Button>
                <Button buttonVariant={'Secondary'} onClick={() => form.reset()}>
                  {diagnosticsSpecialFeaturesTranslations('buttons.cancel.label')}
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
      </Skeleton>
    </div>
  );
};
