import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import clsx from 'clsx';

import { useQuestionnaireQuestionsListForm } from '@/components/functions/PerformanceProfile/Questionnaire/hooks/useQuestionnaireQuestionsListForm';
import {
  IQuestionnaireStoreContext,
  QuestionnaireStoreProvider,
} from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireContext/QuestionnaireContext';
import { QuestionnaireFooter } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireFooter/QuestionnaireFooter';
import { QuestionnaireHeader } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireHeader/QuestionnaireHeader';
import { QuestionnaireNavigation } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireNavigation/QuestionnaireNavigation';
import { QuestionnaireQuestionsList } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionsList/QuestionnaireQuestionsList';
import { QuestionnaireStatusContainer } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireStatus/QuestionnaireStatusContainer';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IQuestionnaire {
  navigateToQuestionnaires: () => void;
}

export const Questionnaire: React.FC<IQuestionnaire> = ({ navigateToQuestionnaires }) => {
  const [competenceAnalysisID, setCompetenceAnalysisID] = useState<number | null>(null);

  const questionnaire = useAppSelector(performanceProfileSelectors.questionnaire);
  const questionnaireStatus = useAppSelector(performanceProfileSelectors.questionnaireStatus);
  const loadingStatus = useAppSelector(performanceProfileSelectors.questionnaireLock);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const detailIDs = useAppSelector(performanceProfileSelectors.detailsIDs);

  const completeLoadingStatus = useAppSelector(
    performanceProfileSelectors.completeQuestionnaireLock
  );

  useEffect(() => {
    if (!detailIDs || !selectedPerson) {
      return;
    }

    if (selectedPerson.id in detailIDs) {
      const competenceAnalysisID = detailIDs[selectedPerson.id];
      setCompetenceAnalysisID(competenceAnalysisID[0]);
      return;
    }

    handleBackToHistory();
  }, [detailIDs, selectedPerson]);

  const personID = selectedPerson?.id;

  const dispatch = useAppDispatch();

  const { form, isAllCompleted } = useQuestionnaireQuestionsListForm(
    questionnaire?.competencies ?? []
  );

  useEffect(() => {
    if (!personID || !competenceAnalysisID) {
      return;
    }

    dispatch(
      performanceProfileActions.fetchQuestionnaire({
        personID: personID,
        competenceAnalysisID: competenceAnalysisID,
      })
    );
  }, [selectedPerson, competenceAnalysisID]);

  useEffect(() => {
    return () => {
      dispatch(performanceProfileActions.setQuestionnaire(null));
    };
  }, []);

  const handleBackToHistory = () => {
    dispatch(performanceProfileActions.setQuestionnaire(null));
    navigateToQuestionnaires();
  };

  const initialContextState: IQuestionnaireStoreContext = {
    isFormEditable: questionnaireStatus !== QuestionnaireStatus.Release,
    collapseTrigger: false,
    expandedGroup: null,
  };

  return (
    <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
      <QuestionnaireStoreProvider state={initialContextState}>
        <div className={'flex flex-col w-full h-full'}>
          <div
            className={clsx(
              'flex-grow flex flex-col gap-2 overflow-hidden',
              completeLoadingStatus === LoadingStatus.LOADING && 'opacity-60 pointer-events-none'
            )}
          >
            <QuestionnaireNavigation navigateToQuestionnaires={handleBackToHistory} />
            <QuestionnaireStatusContainer status={questionnaireStatus} />
            <QuestionnaireHeader />
            <FormProvider {...form}>
              {questionnaire && questionnaire.competencies.length > 0 && (
                <QuestionnaireQuestionsList questionsGroups={questionnaire.competencies} />
              )}
            </FormProvider>
          </div>
          <QuestionnaireFooter
            questionnaireStatus={questionnaireStatus}
            isQuestionnaireCompleted={isAllCompleted}
            competenceAnalysisID={competenceAnalysisID}
            completeLoadingStatus={completeLoadingStatus}
          />
        </div>
      </QuestionnaireStoreProvider>
    </Skeleton>
  );
};
