import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { FormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { IFormRadioButtonGroupItem } from '@/components/FormRadioButtonGroup/FormRadioButtonGroupItem';
import { SerialAppointmentList } from '@/components/functions/ActivityPlanning/components/SerialAppointmentList/SerialAppointmentList';
import { useSerialAppointmentForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/hooks/useSerialAppointmentForm';
import { SerialAppointmentDurationForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentDurationForm';
import { SerialAppointmentPeriodForms } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/SerialAppointmentModal/serialAppointmentForms/serialAppointmentPeriodForms/serialAppointmentPeriodForms';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { dropdownItemsSelectors } from '@/core/redux/slices/dropdownItems/selectors';
import { activityPlanningAppointmentDetailsActions } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import { serialAppointmentModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/selectors';
import { serialAppointmentModalsActions } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const SerialAppointmentModal: React.FC = () => {
  const { isRender } = useAppSelector(serialAppointmentModalsSelectors.serialAppointmentModal);
  return isRender ? <SerialAppointmentModalContent /> : null;
};

export const SerialAppointmentModalContent: React.FC = () => {
  const { t: serialAppointmentModalTranslations } = useTranslation('serialAppointmentModal');

  const { payload, lockStatus } = useAppSelector(
    serialAppointmentModalsSelectors.serialAppointmentModal
  );

  const serialAppointmentTypes = useAppSelector(dropdownItemsSelectors.dropdownItems);
  const dispatch = useAppDispatch();

  const serialAppointmentTypesOptions: IFormRadioButtonGroupItem[] = serialAppointmentTypes?.[
    DropdownItemsByFetch.APPOINTMENT_SERIAL_TYPE
  ]
    ? serialAppointmentTypes?.[DropdownItemsByFetch.APPOINTMENT_SERIAL_TYPE].map((item) => ({
        id: item.id,
        value: item.id,
        component: (
          <label htmlFor={String(item.id)} className={'cursor-pointer'}>
            <Typography>
              {serialAppointmentModalTranslations(`periodTypes.${item.value}.label` as any)}
            </Typography>
          </label>
        ),
      }))
    : [];

  const handleExit = () => {
    dispatch(serialAppointmentModalsActions.closeSerialAppointmentModal());
  };

  const { form } = useSerialAppointmentForm();
  const currentValues = fp.cloneDeep(form.watch());

  useEffect(() => {
    dispatch(
      serialAppointmentModalsActions.fetchSerialAppointmentDetails({
        appointmentSeriesID: payload?.appointmentSeriesID,
      })
    );
  }, [payload?.appointmentSeriesID]);

  const handleReset = () => {
    form.reset();
  };

  const handleCalculateSerialAppointmentList = () => {
    if (!payload) {
      return;
    }

    const { appointmentStartTime, appointmentEndTime, firstAppointmentDate, personID } = payload;

    if (!appointmentStartTime || !appointmentEndTime || !firstAppointmentDate || !personID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentDetailsActions.calculateSerialAppointmentDetails({
        formValues: currentValues,
        personID: personID,
        firstAppointmentDate: firstAppointmentDate,
        appointmentStartTime: appointmentStartTime,
        appointmentEndTime: appointmentEndTime,
      })
    );
  };

  const handleSubmit = () => {
    if (!payload) {
      return;
    }

    form.trigger().then((isValid) => {
      if (!isValid) {
        console.log('Submit');
      }
    });
  };

  useEffect(() => {
    handleCalculateSerialAppointmentList();
  }, [currentValues]);

  const isDirty = form.formState.isDirty;

  const footer = (
    <div className={'flex flex-row gap-3 justify-end'}>
      {isDirty && (
        <Button onClick={handleSubmit}>
          {serialAppointmentModalTranslations('buttons.takeOver.label')}
        </Button>
      )}
      <Button buttonVariant={'Secondary'} onClick={isDirty ? handleReset : handleExit}>
        {serialAppointmentModalTranslations('buttons.cancel.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar
      onExit={handleExit}
      position={'right'}
      isNested={payload?.context === 'modalVersion'}
      onBackTo={handleExit}
      footer={footer}
    >
      <div className={'h-full flex flex-col gap-5 p-5 w-[600px]'}>
        <Skeleton trigger={lockStatus === LoadingStatus.LOADING}>
          <FormProvider {...form}>
            <Typography type={'H2'}>{serialAppointmentModalTranslations('title.label')}</Typography>
            <div className={'h-full flex-1 grid grid-rows-[repeat(11,_1fr)] gap-3'}>
              <div className={'row-span-6 flex flex-col gap-3'}>
                <Typography type={'H3'}>
                  {serialAppointmentModalTranslations('cycle.title.label')}
                </Typography>
                <div className={'flex flex-col gap-3'}>
                  <FormRadioButtonGroup
                    control={form.register('serialTypeID')}
                    options={serialAppointmentTypesOptions}
                    containerClassName={'flex flex-row gap-2 justify-between'}
                  />
                  <SerialAppointmentPeriodForms />
                </div>
              </div>
              <div className={'row-span-5 flex flex-col gap-3'}>
                <Typography type={'H3'}>
                  {serialAppointmentModalTranslations('duration.title.label')}
                </Typography>
                <SerialAppointmentDurationForm />
              </div>
            </div>
            <SerialAppointmentList />
          </FormProvider>
        </Skeleton>
      </div>
    </Sidebar>
  );
};
