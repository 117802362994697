import { createRootSaga } from '@/core/redux/sagas';
import { appSagas } from '@/core/redux/slices/app/sagas';
import { authSagas } from '@/core/redux/slices/auth/sagas';
import { dashboardSagas } from '@/core/redux/slices/dashboard/sagas';
import { dropdownItemsSagas } from '@/core/redux/slices/dropdownItems/sagas';
import { efficacyAssessmentSagas } from '@/core/redux/slices/efficacyAssessment/sagas';
import { functionPageSagas } from '@/core/redux/slices/functionPage/sagas';
import { activityPlaningAppointmentDetailsSagas } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/sagas';
import { activityPlanningAppointmentParticipantsListSagas } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/sagas';
import { activityPlaningMeasuresAppointmentsListSagas } from '@/core/redux/slices/functions/activityPlanning/measuresAppointmentsList/sagas';
import { activityPlaningMeasuresListSagas } from '@/core/redux/slices/functions/activityPlanning/measuresList/sagas';
import { checklistSagas } from '@/core/redux/slices/functions/checklist/sagas';
import { developmentReportGoalsAndActivitySagas } from '@/core/redux/slices/functions/developmentReport/developmentReportGoalsAndActivitySlice/sagas';
import { developmentReportPersonDetailsSagas } from '@/core/redux/slices/functions/developmentReport/developmentReportPersonDetailsSlice/sagas';
import { developmentReportReviewSupportSagas } from '@/core/redux/slices/functions/developmentReport/developmentReportReviewSupportSlice/sagas';
import { developmentReportVisionAndGoalsSagas } from '@/core/redux/slices/functions/developmentReport/developmentReportVisionAndGoalsSlice/sagas';
import { developmentReportSagas } from '@/core/redux/slices/functions/developmentReport/sagas';
import { codesICDSagas } from '@/core/redux/slices/functions/diagnostics/codesICD/sagas';
import { itemsICFSagas } from '@/core/redux/slices/functions/diagnostics/itemsICF/sagas';
import { diagnosticsTypeOfDisabilitySagas } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/sagas';
import { evauluateGoalsSagas } from '@/core/redux/slices/functions/fundingCycle/evaluateGoals/sagas';
import { participationPlanSagas } from '@/core/redux/slices/functions/fundingCycle/participationPlan/sagas';
import { smartGoalsSagas } from '@/core/redux/slices/functions/fundingCycle/smartGoals/sagas';
import { actionCourseSagas } from '@/core/redux/slices/functions/integrationPlan/actionCourse/sagas';
import { competenceAnalysisSagas } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/sagas';
import { generalDataSagas } from '@/core/redux/slices/functions/integrationPlan/generalData/sagas';
import { participantExpectationSagas } from '@/core/redux/slices/functions/integrationPlan/participantExpectation/sagas';
import { qualificationDocumentationSagas } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/sagas';
import { integrationPlanSagas } from '@/core/redux/slices/functions/integrationPlan/sagas';
import { statusReportSagas } from '@/core/redux/slices/functions/integrationPlan/statusReport/sagas';
import { summaryFASagas } from '@/core/redux/slices/functions/integrationPlan/summaryFA/sagas';
import { disabilityCharacteristicsSagas } from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/sagas';
import { diagnosticsSpecialFeaturesSagas } from '@/core/redux/slices/functions/medicalInformation/specialFeatures/sagas';
import { medicationSagas } from '@/core/redux/slices/functions/medication/sagas';
import { organizationalUnitsSagas } from '@/core/redux/slices/functions/organizationalUnits/sagas';
import { performanceProfileSagas } from '@/core/redux/slices/functions/performanceProfile/sagas';
import { addressesDataSagas } from '@/core/redux/slices/functions/personGeneralData/addresses/sagas';
import { personDataSagas } from '@/core/redux/slices/functions/personGeneralData/personData/sagas';
import { simpleSelfAssessmentSagas } from '@/core/redux/slices/functions/simpleSelfAssesment/sagas';
import { specialistGeneralDataSagas } from '@/core/redux/slices/functions/specialistGeneralData/generalData/sagas';
import { testingResultSagas } from '@/core/redux/slices/functions/testingResult/sagas';
import { vocationalTrainingAreaSagas } from '@/core/redux/slices/functions/vocationalTrainingArea/sagas';
import { mobilitySagas } from '@/core/redux/slices/functions/workingTimeMobility/mobility/sagas';
import { workingTimeSagas } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/sagas';
import { logbookSagas } from '@/core/redux/slices/logbook/sagas';
import { medicationAdministrationSagas } from '@/core/redux/slices/medicationAdministration/sagas';
import { activityPlaningAppointmentDetailsModalsSagas } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/saga';
import { activityPlaningParticipantsModalsSagas } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningParticipants/saga';
import { serialAppointmentModalSagas } from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlanningSerialAppointment/saga';
import { developmentReportVersionHistorySagas } from '@/core/redux/slices/modalsSlice/functions/developmentReport/developmentReportVersionHistory/saga';
import { diagnosticsModalsSagas } from '@/core/redux/slices/modalsSlice/functions/diagnostics/sagas';
import { integrationPlanModalsSagas } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/sagas';
import { mobilityModalsSagas } from '@/core/redux/slices/modalsSlice/functions/mobility/sagas';
import { organizationalUnitsModalsSagas } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/sagas';
import { selfAssessmentModalsSagas } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/sagas';
import { testingResultModalsSagas } from '@/core/redux/slices/modalsSlice/functions/testingResult/sagas';
import { vocationalTrainingAreaModalsSagas } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/saga';
import { workingTimeMobilityModalsSagas } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/sagas';
import { functionsPageModalsSagas } from '@/core/redux/slices/modalsSlice/functionsPage/sagas';
import { notificationsSagas } from '@/core/redux/slices/notifications/sagas';
import { parametersSagas } from '@/core/redux/slices/parameters/sagas';
import { personPageSagas } from '@/core/redux/slices/personPage/sagas';
import { reportsSagas } from '@/core/redux/slices/reports/sagas';
import { userSettingsSagas } from '@/core/redux/slices/userSettings/sagas';

export const rootSaga = createRootSaga([
  ...appSagas,
  ...authSagas,
  ...userSettingsSagas,
  ...dashboardSagas,
  ...functionPageSagas,
  ...notificationsSagas,
  ...personPageSagas,
  ...simpleSelfAssessmentSagas,
  ...performanceProfileSagas,
  ...generalDataSagas,
  ...actionCourseSagas,
  ...competenceAnalysisSagas,
  ...participantExpectationSagas,
  ...summaryFASagas,
  ...statusReportSagas,
  ...qualificationDocumentationSagas,
  ...integrationPlanModalsSagas,
  ...integrationPlanSagas,
  ...diagnosticsModalsSagas,
  ...codesICDSagas,
  ...itemsICFSagas,
  ...testingResultSagas,
  ...testingResultModalsSagas,
  ...specialistGeneralDataSagas,
  ...mobilitySagas,
  ...workingTimeMobilityModalsSagas,
  ...functionsPageModalsSagas,
  ...personDataSagas,
  ...addressesDataSagas,
  ...dropdownItemsSagas,
  ...vocationalTrainingAreaSagas,
  ...vocationalTrainingAreaModalsSagas,
  ...workingTimeSagas,
  ...parametersSagas,
  ...medicationSagas,
  ...medicationAdministrationSagas,
  ...selfAssessmentModalsSagas,
  ...organizationalUnitsSagas,
  ...organizationalUnitsModalsSagas,
  ...activityPlaningMeasuresListSagas,
  ...activityPlaningMeasuresAppointmentsListSagas,
  ...activityPlanningAppointmentParticipantsListSagas,
  ...activityPlaningAppointmentDetailsSagas,
  ...activityPlaningAppointmentDetailsModalsSagas,
  ...logbookSagas,
  ...efficacyAssessmentSagas,
  ...reportsSagas,
  ...activityPlaningParticipantsModalsSagas,
  ...mobilityModalsSagas,
  ...participationPlanSagas,
  ...smartGoalsSagas,
  ...evauluateGoalsSagas,
  ...developmentReportSagas,
  ...developmentReportPersonDetailsSagas,
  ...developmentReportVisionAndGoalsSagas,
  ...developmentReportReviewSupportSagas,
  ...developmentReportGoalsAndActivitySagas,
  ...checklistSagas,
  ...developmentReportVersionHistorySagas,
  ...diagnosticsTypeOfDisabilitySagas,
  ...disabilityCharacteristicsSagas,
  ...diagnosticsSpecialFeaturesSagas,
  ...serialAppointmentModalSagas,
]);
