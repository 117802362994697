import { PerformanceProfileErrorsTypes } from '@/core/enums/functions/performanceProfile/performanceProfileErrorsTypesEnum';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { store } from '@/core/redux/store';

export const performanceProfileErrorHandler: Record<number, () => void> = {
  601: () => {
    console.log('No allocated catalogs');
    store.dispatch(
      performanceProfileActions.setPerformanceProfileErrors({
        [PerformanceProfileErrorsTypes.NoAllocatedCatalogs]: true,
      })
    );
  },
};
