import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  disabilityCharacteristicsActions,
  IDisabilityCharacteristic,
  IDisabilityCharacteristicsFetchPayload,
  IDisabilityCharacteristicsUpdatePayload,
} from '@/core/redux/slices/functions/medicalInformation/disabilityCharacteristics/slice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type DisabilityCharacteristicsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/severely_disabled_person_pass',
  'get',
  '200'
>;
type DisabilityCharacteristicsFetchRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/severely_disabled_person_pass',
  'get'
>;

type DisabilityCharacteristicsUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/severely_disabled_person_pass',
  'post'
>;

function* fetchDisabilityCharacteristics(
  action: PayloadAction<IDisabilityCharacteristicsFetchPayload>
): Generator<any, void, DisabilityCharacteristicsResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      disabilityCharacteristicsActions.setDisabilityCharacteristicsLock(LoadingStatus.LOADING)
    );

    const request: DisabilityCharacteristicsFetchRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/severely_disabled_person_pass',
      'get',
      request
    );
    const mappedResponse = response.characteristics.map<IDisabilityCharacteristic>(
      (characteristic) => ({
        id: characteristic.id,
        name: characteristic.name,
        isSelected: characteristic.is_selected,
      })
    );

    yield put(disabilityCharacteristicsActions.setDisabilityCharacteristics(mappedResponse));

    yield put(
      disabilityCharacteristicsActions.setDisabilityCharacteristicsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics disability characteristics fetch', error);
    yield put(
      disabilityCharacteristicsActions.setDisabilityCharacteristicsLock(LoadingStatus.ERROR)
    );
  }
}

function* updateDisabilityCharacteristics(
  action: PayloadAction<IDisabilityCharacteristicsUpdatePayload>
): Generator<any, void, any> {
  const { personID, formValues } = action.payload;

  try {
    yield put(
      disabilityCharacteristicsActions.setUpdateDisabilityCharacteristicsLock(LoadingStatus.LOADING)
    );

    const characteristicsKeys = Object.keys(formValues);

    const request: DisabilityCharacteristicsUpdateRequest = {
      json: characteristicsKeys.map<DisabilityCharacteristicsUpdateRequest['json'][0]>((key) => ({
        id: Number(key),
        is_selected: formValues[key],
        person_id: personID,
      })),
      ...authAdd(),
    };

    yield call(restCall, '/severely_disabled_person_pass', 'post', request);

    yield put(
      disabilityCharacteristicsActions.setUpdateDisabilityCharacteristicsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics disability characteristics update', error);
    yield put(
      disabilityCharacteristicsActions.setUpdateDisabilityCharacteristicsLock(LoadingStatus.ERROR)
    );
  }
}

export const disabilityCharacteristicsSagas = [
  takeLatest(
    disabilityCharacteristicsActions.fetchDisabilityCharacteristics,
    fetchDisabilityCharacteristics
  ),
  takeLatest(
    disabilityCharacteristicsActions.updateDisabilityCharacteristics,
    updateDisabilityCharacteristics
  ),
];
