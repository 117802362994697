import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Input } from '@/components/Input/Input';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Pagination } from '@/components/Pagination/Pagination';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { codesICDActions } from '@/core/redux/slices/functions/diagnostics/codesICD/codesICDSlice';
import { codesICDSelectors } from '@/core/redux/slices/functions/diagnostics/codesICD/selectors';
import { diagnosticsModalsActions } from '@/core/redux/slices/modalsSlice/functions/diagnostics/diagnosticsModalsSlice';
import { diagnosticsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/diagnostics/selector';

const paginationSize = 10;
const paginationChunkSize = 3;

const searchInput = 'search-icd-codes';
const selectedCheckbox = 'check-selected-codes';

const ModalContent: React.FC = () => {
  const { t: addICDCodesModalTranslations } = useTranslation('addICDCodesModal');

  const columns: ITableColumn[] = [
    {
      header: addICDCodesModalTranslations('columns.code.label'),
      accessor: 'code',
      className: 'w-1/3',
      template: (htmlString: string) => (
        <div className='[&>span]:text-blue' dangerouslySetInnerHTML={{ __html: htmlString }} />
      ),
    },
    {
      header: addICDCodesModalTranslations('columns.description.label'),
      accessor: 'description',
      className: 'w-2/3',
      template: (htmlString: string) => (
        <div
          className='whitespace-normal [&>span]:text-blue'
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      ),
    },
  ];

  const { payload } = useAppSelector(diagnosticsModalsSelectors.addICDCodesModal);
  const personID = payload?.personID;
  const availableCodes = useAppSelector(codesICDSelectors.availableCodesICDData);
  const totalCountAvailable = useAppSelector(codesICDSelectors.availableCodesICDTotalCount);

  const dispatch = useAppDispatch();

  const methods = useForm();
  const searchValue = methods.watch(searchInput);
  const checkValue = methods.watch(selectedCheckbox);

  const [selectedPage, setSelectedPage] = useState<number>(1);

  const defaultSelectedCodes = useMemo(
    () => availableCodes.filter((code) => code.isSelected).map((code) => code.id),
    [availableCodes]
  );

  const onSelectedCodesChange = (selectedCodes: string[]) => {
    if (!personID) {
      return;
    }

    const updatedCodes = availableCodes.map((item) => ({
      code: String(item.code),
      isSelected: selectedCodes.includes(item.id),
    }));

    dispatch(
      codesICDActions.updateCodesICD({
        personID: personID,
        updatedCodes: updatedCodes,
      })
    );
  };

  const handleExit = () => {
    if (!personID) {
      return;
    }

    dispatch(
      codesICDActions.fetchCodesICD({
        personID: personID,
        limit: 0,
        page: 0,
      })
    );
    dispatch(diagnosticsModalsActions.closeAddICDCodesModal());
  };

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      codesICDActions.fetchAvailableICDCodes({
        personID: personID,
        limit: paginationSize,
        page: selectedPage - 1,
        search: searchValue,
        isSelected: checkValue || undefined,
      })
    );
  }, [dispatch, selectedPage, personID, searchValue, checkValue]);

  useEffect(() => {
    setSelectedPage(1);
  }, [checkValue, searchValue]);

  const TableHeader = (
    <div className='flex gap-4 items-center px-5'>
      <div className='grow'>
        <Input
          inputVariant='WidgetSearch'
          placeholder={addICDCodesModalTranslations('tableHeader.filter.placeholder')}
          control={methods.control.register(searchInput)}
        />
      </div>
      <CheckboxItem
        label={addICDCodesModalTranslations('tableHeader.showOnlySelected.label')}
        fieldName={selectedCheckbox}
      />
    </div>
  );

  const TableFooter = (
    <div>
      <Pagination
        total={totalCountAvailable}
        selectedPage={selectedPage}
        paginationSize={paginationSize}
        paginationChunkSize={paginationChunkSize}
        setSelectedPage={setSelectedPage}
        className={'px-5'}
      />
    </div>
  );

  return (
    <Sidebar
      position={'right'}
      title={addICDCodesModalTranslations('title.label')}
      onExit={handleExit}
    >
      <div className={'h-full flex flex-col'}>
        <FormProvider {...methods}>
          <Table
            columns={columns}
            data={availableCodes}
            initialSelectedState={defaultSelectedCodes}
            header={TableHeader}
            footer={TableFooter}
            onMultiSelectChange={onSelectedCodesChange}
            className='flex-1 w-[600px] min-h-[300px]'
          />
        </FormProvider>
        <div className='flex justify-end items-center px-5 py-3'>
          <Button onClick={handleExit}>
            {addICDCodesModalTranslations('buttons.confirm.label')}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export const AddICDCodesModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(diagnosticsModalsSelectors.addICDCodesModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
