import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import datepickerDE from '@/locales/deDE/components/datepicker.json';
import dialogDE from '@/locales/deDE/components/dialog.json';
import integrationPlanNotificationsDE from '@/locales/deDE/components/notifications/integrationPlanNotifications.json';
import selectDE from '@/locales/deDE/components/select.json';
import uploadZoneDE from '@/locales/deDE/components/uploadZone.json';
import workingTimePlannerDE from '@/locales/deDE/components/workingTimePlanner.json';
import activityPlanningDE from '@/locales/deDE/functions/activityPlanning/activityPlanning.json';
import additionalPersonRequirementDE from '@/locales/deDE/functions/addtionalPersonRequirement/additionalPersonRequirement.json';
import checklistDE from '@/locales/deDE/functions/checklist/checklist.json';
import developmentReportDE from '@/locales/deDE/functions/developmentReport/developmentReport.json';
import developmentReportGoalsAndActivityDE from '@/locales/deDE/functions/developmentReport/forms/developmentReportGoalsAndActivity.json';
import developmentReportPersonDetailsDE from '@/locales/deDE/functions/developmentReport/forms/developmentReportPersonDetails.json';
import developmentReportReviewSupportDE from '@/locales/deDE/functions/developmentReport/forms/developmentReportReviewSupport.json';
import developmentReportVisionAndGoalsDE from '@/locales/deDE/functions/developmentReport/forms/developmentReportVisionAndGoals.json';
import codesICDDE from '@/locales/deDE/functions/diagnostics/codesICD.json';
import diagnosticsDE from '@/locales/deDE/functions/diagnostics/diagnostics.json';
import itemsICFDE from '@/locales/deDE/functions/diagnostics/itemsICF.json';
import typeOfDisabilityDE from '@/locales/deDE/functions/diagnostics/typeOfDisability.json';
import evaluateGoalsDE from '@/locales/deDE/functions/fundingCycle/evaluateGoals.json';
import fundingCycleDE from '@/locales/deDE/functions/fundingCycle/fundingCycle.json';
import goalsWishesDE from '@/locales/deDE/functions/fundingCycle/goalsWishes.json';
import measuresCarryingDE from '@/locales/deDE/functions/fundingCycle/measuresCarrying.json';
import participationPlanDE from '@/locales/deDE/functions/fundingCycle/participationPlan.json';
import smartGoalsDE from '@/locales/deDE/functions/fundingCycle/smartGoals.json';
import actionCourseDE from '@/locales/deDE/functions/integrationPlan/actionCourse.json';
import competenceAnalysisDE from '@/locales/deDE/functions/integrationPlan/competenceAnalysis.json';
import generalDataDE from '@/locales/deDE/functions/integrationPlan/generalData.json';
import integrationPlanDE from '@/locales/deDE/functions/integrationPlan/integrationPlan.json';
import participantExpectationDE from '@/locales/deDE/functions/integrationPlan/participantExpectation.json';
import qualificationDocumentationDE from '@/locales/deDE/functions/integrationPlan/qualificationDocumentation.json';
import statusReportDE from '@/locales/deDE/functions/integrationPlan/statusReport.json';
import summaryFADE from '@/locales/deDE/functions/integrationPlan/summaryFA.json';
import legalGuardianDE from '@/locales/deDE/functions/legalGuardian/legalGuardian.json';
import diagnosticsSpecialFeaturesDE from '@/locales/deDE/functions/medicalInformation/diagnosticsSpecialFeatures/diagnosticsSpecialFeatures.json';
import disabilityCharacteristicsDE from '@/locales/deDE/functions/medicalInformation/disabilityCharacteristics/disabilityCharacteristics.json';
import medicalInformationDE from '@/locales/deDE/functions/medicalInformation/medicalInformation.json';
import medicationDE from '@/locales/deDE/functions/medication/medication.json';
import mobilityDE from '@/locales/deDE/functions/mobility/mobility.json';
import organizationalUnitsDE from '@/locales/deDE/functions/organizationalUnits/organizationalUnits.json';
import performanceProfileDE from '@/locales/deDE/functions/performanceProfile/performanceProfile.json';
import performanceProfileChartsDE from '@/locales/deDE/functions/performanceProfile/performanceProfileCharts.json';
import questionnaireDE from '@/locales/deDE/functions/performanceProfile/questionnaire.json';
import addressesDE from '@/locales/deDE/functions/personGeneralData/addresses.json';
import personGeneralDataDE from '@/locales/deDE/functions/personGeneralData/personGeneralData.json';
import selfAssesmentDE from '@/locales/deDE/functions/selfAssesment/selfAssesment.json';
import specialistGeneralDataDE from '@/locales/deDE/functions/specialistGeneralData/specialistGeneralData.json';
import testingResultDE from '@/locales/deDE/functions/testingResult/testingResult.json';
import vocationalTrainingAreaDE from '@/locales/deDE/functions/vocationalTrainingArea/vocationalTrainingArea.json';
import workingTimeDE from '@/locales/deDE/functions/workingTime/workingTime.json';
import efficacyAssessmentModalDE from '@/locales/deDE/modals/efficacyAssessment/efficacyAssessmentModal.json';
import recordingEffectivenessModalDE from '@/locales/deDE/modals/efficacyAssessment/recordingEffectivenessModal.json';
import activityPlanningAddParticipantsModalDE from '@/locales/deDE/modals/functions/activityPlaning/addParticipantsModal.json';
import completeAppointmentModalDE from '@/locales/deDE/modals/functions/activityPlaning/completeAppointmentModal.json';
import serialAppointmentModalDE from '@/locales/deDE/modals/functions/activityPlaning/serialAppointmentModal.json';
import developmentReportVersionHistoryModalDE from '@/locales/deDE/modals/functions/developmentReport/developmentReportVersionHistoryModal.json';
import addICDCodesModalDE from '@/locales/deDE/modals/functions/diagnostics/addICDCodesModal.json';
import addICFItemsModalDE from '@/locales/deDE/modals/functions/diagnostics/addICFItemsModal.json';
import diagnosticsDisabilityModalDE from '@/locales/deDE/modals/functions/diagnostics/diagnosticsDisabilityModal.json';
import addMeasuresModalDE from '@/locales/deDE/modals/functions/fundingCycle/addMeasuresModal.json';
import confirmDeleteMeasureModalDE from '@/locales/deDE/modals/functions/fundingCycle/confirmDeleteMeasureModal.json';
import confirmDeleteSmartGoalModalDE from '@/locales/deDE/modals/functions/fundingCycle/confirmDeleteSmartGoalModal.json';
import confirmUnpinActivityModalDE from '@/locales/deDE/modals/functions/fundingCycle/confirmUnpinActivityModal.json';
import displayGoalCheckModalDE from '@/locales/deDE/modals/functions/fundingCycle/displayGoalCheckModal.json';
import displayParticipationPlanModalDE from '@/locales/deDE/modals/functions/fundingCycle/displayParticipationPlanModal.json';
import displaySmartGoalModalDE from '@/locales/deDE/modals/functions/fundingCycle/displaySmartGoalModal.json';
import actionCourseCreatedDateModalDE from '@/locales/deDE/modals/functions/integrationPlan/actionCourseCreatedDateModal.json';
import activityPersonsListModalDE from '@/locales/deDE/modals/functions/integrationPlan/activityPersonsListModal.json';
import competenceAnalysisImportModalDE from '@/locales/deDE/modals/functions/integrationPlan/competenceAnalysisImportModal.json';
import createIntegrationPlanDocumentsModalDE from '@/locales/deDE/modals/functions/integrationPlan/createIntegrationPlanDocumentsModal.json';
import generalDataUpdatedDataModalDE from '@/locales/deDE/modals/functions/integrationPlan/generalDataUpdatedDataModal.json';
import integrationPlanVersionHistoryModalDE from '@/locales/deDE/modals/functions/integrationPlan/integrationPlanVersionHistoryModal.json';
import internshipDocumentationDE from '@/locales/deDE/modals/functions/integrationPlan/internshipDocumentationModal.json';
import qualificationObjectivesModalDE from '@/locales/deDE/modals/functions/integrationPlan/qualificationObjectivesModal.json';
import displayMedicationModalDE from '@/locales/deDE/modals/functions/medication/displayMedicationModal.json';
import stopAdministrationModalDE from '@/locales/deDE/modals/functions/medication/stopAdministrationModal.json';
import additionalPersonRequirementModalDE from '@/locales/deDE/modals/functions/organizationalUnits/additionalPersonRequirementModal.json';
import createOrganizationalUnitModalDE from '@/locales/deDE/modals/functions/organizationalUnits/createOrganizationalUnitModal.json';
import organizationalUnitsFormsDE from '@/locales/deDE/modals/functions/organizationalUnits/organizationalUnitsForms.json';
import personRequirementAssessmentPrognosisModalDE from '@/locales/deDE/modals/functions/organizationalUnits/personRequirementAssessmentPrognosisModal.json';
import personRequirementTimeListModalDE from '@/locales/deDE/modals/functions/organizationalUnits/personRequirementTimeListModal.json';
import updateOrganizationalUnitModalDE from '@/locales/deDE/modals/functions/organizationalUnits/updateOrganizationalUnitModal.json';
import selectColleagueModalDE from '@/locales/deDE/modals/functions/performanceProfile/selectColleagueModal.json';
import startNewQuestionnaireModallDE from '@/locales/deDE/modals/functions/performanceProfile/startNewQuestionnaireModal.json';
import deleteAddressModalDE from '@/locales/deDE/modals/functions/personGeneralData/deleteAddressModal.json';
import displayAddressModalDE from '@/locales/deDE/modals/functions/personGeneralData/displayAddressModal.json';
import simpleQuestionnaireModalDE from '@/locales/deDE/modals/functions/selfAssesment/simpleQuestionnaireModal.json';
import startQuestionnaireModalDE from '@/locales/deDE/modals/functions/selfAssesment/startQuestionnaireModal.json';
import createAppliedTestProcedureModalDE from '@/locales/deDE/modals/functions/testingResult/createAppliedTestProcedureModal.json';
import deleteAppliedTestProcedureModalDE from '@/locales/deDE/modals/functions/testingResult/deleteAppliedTestProcedureModal.json';
import importInternalDifferentiationModalDE from '@/locales/deDE/modals/functions/testingResult/importInternalDifferentiationModal.json';
import updateAppliedTestProcedureModalDE from '@/locales/deDE/modals/functions/testingResult/updateAppliedTestProcedureModal.json';
import completeMeasureModalDE from '@/locales/deDE/modals/functions/vocationalTrainingArea/completeMeasureModal.json';
import vocationalTrainingAreaDocumentsDE from '@/locales/deDE/modals/functions/vocationalTrainingArea/vocationalTrainingAreaDocuments.json';
import mobilityTransportTypesModalDE from '@/locales/deDE/modals/functions/workingTimeMobility/mobilityTransportTypesModal.json';
import workingTimePredefinedVariantsModalDE from '@/locales/deDE/modals/functions/workingTimeMobility/workingTimePredefinedVariantsModal.json';
import logbookAddModalDE from '@/locales/deDE/modals/functionsPage/logbook/logbookAddModal.json';
import logbookEditModalDE from '@/locales/deDE/modals/functionsPage/logbook/logbookEditModal.json';
import logbookListModalDE from '@/locales/deDE/modals/functionsPage/logbook/logbookListModal.json';
import logbookViewModalDE from '@/locales/deDE/modals/functionsPage/logbook/logbookViewModal.json';
import personInfoModalDE from '@/locales/deDE/modals/functionsPage/personInfoModal.json';
import confirmMarkModalDE from '@/locales/deDE/modals/views/medicationAdministration/confirmMarkModal.json';
import confirmUnmarkModalDE from '@/locales/deDE/modals/views/medicationAdministration/confirmUnmarkModal.json';
import medicationAdministrationHistoryDE from '@/locales/deDE/modals/views/medicationAdministration/medicationAdministrationHistory.json';
import deTMS from '@/locales/deDE/tms.json';
import functionsPageDE from '@/locales/deDE/views/functionsPage/functionsPage.json';
import medicationAdministrationDE from '@/locales/deDE/views/medicationAdministration/medicationAdministration.json';
import reportsDE from '@/locales/deDE/views/reports/reports.json';
import activitiesWidgetDE from '@/locales/deDE/widgets/activitiesWidget.json';
import favoritePersonWidgetDE from '@/locales/deDE/widgets/favoritePersonWidget.json';
import datepickerEN from '@/locales/enUS/components/datepicker.json';
import dialogEN from '@/locales/enUS/components/dialog.json';
import integrationPlanNotificationsEN from '@/locales/enUS/components/notifications/integrationPlanNotifications.json';
import selectEN from '@/locales/enUS/components/select.json';
import uploadZoneEN from '@/locales/enUS/components/uploadZone.json';
import workingTimePlannerEN from '@/locales/enUS/components/workingTimePlanner.json';
import activityPlanningEN from '@/locales/enUS/functions/activityPlanning/activityPlanning.json';
import additionalPersonRequirementEN from '@/locales/enUS/functions/addtionalPersonRequirement/additionalPersonRequirement.json';
import checklistEN from '@/locales/enUS/functions/checklist/checklist.json';
import developmentReportEN from '@/locales/enUS/functions/developmentReport/developmentReport.json';
import developmentReportGoalsAndActivityEN from '@/locales/enUS/functions/developmentReport/forms/developmentReportGoalsAndActivity.json';
import developmentReportPersonDetailsEN from '@/locales/enUS/functions/developmentReport/forms/developmentReportPersonDetails.json';
import developmentReportReviewSupportEN from '@/locales/enUS/functions/developmentReport/forms/developmentReportReviewSupport.json';
import developmentReportVisionAndGoalsEN from '@/locales/enUS/functions/developmentReport/forms/developmentReportVisionAndGoals.json';
import codesICDEN from '@/locales/enUS/functions/diagnostics/codesICD.json';
import diagnosticsEN from '@/locales/enUS/functions/diagnostics/diagnostics.json';
import itemsICFEN from '@/locales/enUS/functions/diagnostics/itemsICF.json';
import typeOfDisabilityEN from '@/locales/enUS/functions/diagnostics/typeOfDisability.json';
import evaluateGoalsEN from '@/locales/enUS/functions/fundingCycle/evaluateGoals.json';
import fundingCycleEN from '@/locales/enUS/functions/fundingCycle/fundingCycle.json';
import goalsWishesEN from '@/locales/enUS/functions/fundingCycle/goalsWishes.json';
import measuresCarryingEN from '@/locales/enUS/functions/fundingCycle/measuresCarrying.json';
import participationPlanEN from '@/locales/enUS/functions/fundingCycle/participationPlan.json';
import smartGoalsEN from '@/locales/enUS/functions/fundingCycle/smartGoals.json';
import actionCourseEN from '@/locales/enUS/functions/integrationPlan/actionCourse.json';
import competenceAnalysisEN from '@/locales/enUS/functions/integrationPlan/competenceAnalysis.json';
import generalDataEN from '@/locales/enUS/functions/integrationPlan/generalData.json';
import integrationPlanEN from '@/locales/enUS/functions/integrationPlan/integrationPlan.json';
import participantExpectationEN from '@/locales/enUS/functions/integrationPlan/participantExpectation.json';
import qualificationDocumentationEN from '@/locales/enUS/functions/integrationPlan/qualificationDocumentation.json';
import statusReportEN from '@/locales/enUS/functions/integrationPlan/statusReport.json';
import summaryFAEN from '@/locales/enUS/functions/integrationPlan/summaryFA.json';
import legalGuardianEN from '@/locales/enUS/functions/legalGuardian/legalGuardian.json';
import diagnosticsSpecialFeaturesEN from '@/locales/enUS/functions/medicalInformation/diagnosticsSpecialFeatures/diagnosticsSpecialFeatures.json';
import disabilityCharacteristicsEN from '@/locales/enUS/functions/medicalInformation/disabilityCharacteristics/disabilityCharacteristics.json';
import medicalInformationEN from '@/locales/enUS/functions/medicalInformation/medicalInformation.json';
import medicationEN from '@/locales/enUS/functions/medication/medication.json';
import mobilityEN from '@/locales/enUS/functions/mobility/mobility.json';
import organizationalUnitsEN from '@/locales/enUS/functions/organizationalUnits/organizationalUnits.json';
import performanceProfileEN from '@/locales/enUS/functions/performanceProfile/performanceProfile.json';
import performanceProfileChartsEN from '@/locales/enUS/functions/performanceProfile/performanceProfileCharts.json';
import questionnaireEN from '@/locales/enUS/functions/performanceProfile/questionnaire.json';
import addressesEN from '@/locales/enUS/functions/personGeneralData/addresses.json';
import personGeneralDataEN from '@/locales/enUS/functions/personGeneralData/personGeneralData.json';
import selfAssesmentEN from '@/locales/enUS/functions/selfAssesment/selfAssesment.json';
import specialistGeneralDataEN from '@/locales/enUS/functions/specialistGeneralData/specialistGeneralData.json';
import testingResultEN from '@/locales/enUS/functions/testingResult/testingResult.json';
import vocationalTrainingAreaEN from '@/locales/enUS/functions/vocationalTrainingArea/vocationalTrainingArea.json';
import workingTimeEN from '@/locales/enUS/functions/workingTime/workingTime.json';
import efficacyAssessmentModalEN from '@/locales/enUS/modals/efficacyAssessment/efficacyAssessmentModal.json';
import recordingEffectivenessModalEN from '@/locales/enUS/modals/efficacyAssessment/recordingEffectivenessModal.json';
import activityPlanningAddParticipantsModalEN from '@/locales/enUS/modals/functions/activityPlaning/addParticipantsModal.json';
import completeAppointmentModalEN from '@/locales/enUS/modals/functions/activityPlaning/completeAppointmentModal.json';
import serialAppointmentModalEN from '@/locales/enUS/modals/functions/activityPlaning/serialAppointmentModal.json';
import developmentReportVersionHistoryModalEN from '@/locales/enUS/modals/functions/developmentReport/developmentReportVersionHistoryModal.json';
import addICDCodesModalEN from '@/locales/enUS/modals/functions/diagnostics/addICDCodesModal.json';
import addICFItemsModalEN from '@/locales/enUS/modals/functions/diagnostics/addICFItemsModal.json';
import diagnosticsDisabilityModalEN from '@/locales/enUS/modals/functions/diagnostics/diagnosticsDisabilityModal.json';
import addMeasuresModalEN from '@/locales/enUS/modals/functions/fundingCycle/addMeasuresModal.json';
import confirmDeleteMeasureModalEN from '@/locales/enUS/modals/functions/fundingCycle/confirmDeleteMeasureModal.json';
import confirmDeleteSmartGoalModalEN from '@/locales/enUS/modals/functions/fundingCycle/confirmDeleteSmartGoal.json';
import confirmUnpinActivityModalEN from '@/locales/enUS/modals/functions/fundingCycle/confirmUnpinActivityModal.json';
import displayGoalCheckModalEN from '@/locales/enUS/modals/functions/fundingCycle/displayGoalCheckModal.json';
import displayParticipationPlanModalEN from '@/locales/enUS/modals/functions/fundingCycle/displayParticipationPlanModal.json';
import displaySmartGoalModalEN from '@/locales/enUS/modals/functions/fundingCycle/displaySmartGoalModal.json';
import actionCourseCreatedDateModalEN from '@/locales/enUS/modals/functions/integrationPlan/actionCourseCreatedDateModal.json';
import activityPersonsListModalEN from '@/locales/enUS/modals/functions/integrationPlan/activityPersonsListModal.json';
import competenceAnalysisImportModalEN from '@/locales/enUS/modals/functions/integrationPlan/competenceAnalysisImportModal.json';
import createIntegrationPlanDocumentsModalEN from '@/locales/enUS/modals/functions/integrationPlan/createIntegrationPlanDocumentsModal.json';
import generalDataUpdatedDataModalEN from '@/locales/enUS/modals/functions/integrationPlan/generalDataUpdatedDataModal.json';
import integrationPlanVersionHistoryModalEN from '@/locales/enUS/modals/functions/integrationPlan/integrationPlanVersionHistoryModal.json';
import internshipDocumentationEN from '@/locales/enUS/modals/functions/integrationPlan/internshipDocumentationModal.json';
import qualificationObjectivesModalEN from '@/locales/enUS/modals/functions/integrationPlan/qualificationObjectivesModal.json';
import displayMedicationModalEN from '@/locales/enUS/modals/functions/medication/displayMedicationModal.json';
import stopAdministrationModalEN from '@/locales/enUS/modals/functions/medication/stopAdministrationModal.json';
import additionalPersonRequirementModalEN from '@/locales/enUS/modals/functions/organizationalUnits/additionalPersonRequirementModal.json';
import createOrganizationalUnitModalEN from '@/locales/enUS/modals/functions/organizationalUnits/createOrganizationalUnitModal.json';
import organizationalUnitsFormsEN from '@/locales/enUS/modals/functions/organizationalUnits/organizationalUnitsForms.json';
import personRequirementAssessmentPrognosisModalEN from '@/locales/enUS/modals/functions/organizationalUnits/personRequirementAssessmentPrognosisModal.json';
import personRequirementTimeListModalEN from '@/locales/enUS/modals/functions/organizationalUnits/personRequirementTimeListModal.json';
import updateOrganizationalUnitModalEN from '@/locales/enUS/modals/functions/organizationalUnits/updateOrganizationalUnitModal.json';
import selectColleagueModalEN from '@/locales/enUS/modals/functions/performanceProfile/selectColleagueModal.json';
import startNewQuestionnaireModalEN from '@/locales/enUS/modals/functions/performanceProfile/startNewQuestionnaireModal.json';
import deleteAddressModalEN from '@/locales/enUS/modals/functions/personGeneralData/deleteAddressModal.json';
import displayAddressModalEN from '@/locales/enUS/modals/functions/personGeneralData/displayAddressModal.json';
import simpleQuestionnaireModalEN from '@/locales/enUS/modals/functions/selfAssesment/simpleQuestionnaireModal.json';
import startQuestionnaireModalEN from '@/locales/enUS/modals/functions/selfAssesment/startQuestionnaireModal.json';
import createAppliedTestProcedureModalEN from '@/locales/enUS/modals/functions/testingResult/createAppliedTestProcedureModal.json';
import deleteAppliedTestProcedureModalEN from '@/locales/enUS/modals/functions/testingResult/deleteAppliedTestProcedureModal.json';
import importInternalDifferentiationModalEN from '@/locales/enUS/modals/functions/testingResult/importInternalDifferentiationModal.json';
import updateAppliedTestProcedureModalEN from '@/locales/enUS/modals/functions/testingResult/updateAppliedTestProcedureModal.json';
import completeMeasureModalEN from '@/locales/enUS/modals/functions/vocationalTrainingArea/completeMeasureModal.json';
import vocationalTrainingAreaDocumentsEN from '@/locales/enUS/modals/functions/vocationalTrainingArea/vocationalTrainingAreaDocuments.json';
import mobilityTransportTypesModalEN from '@/locales/enUS/modals/functions/workingTimeMobility/mobilityTransportTypesModal.json';
import workingTimePredefinedVariantsModalEN from '@/locales/enUS/modals/functions/workingTimeMobility/workingTimePredefinedVariantsModal.json';
import logbookAddModalEN from '@/locales/enUS/modals/functionsPage/logbook/logbookAddModal.json';
import logbookEditModalEN from '@/locales/enUS/modals/functionsPage/logbook/logbookEditModal.json';
import logbookListModalEN from '@/locales/enUS/modals/functionsPage/logbook/logbookListModal.json';
import logbookViewModalEN from '@/locales/enUS/modals/functionsPage/logbook/logbookViewModal.json';
import personInfoModalEN from '@/locales/enUS/modals/functionsPage/personInfoModal.json';
import confirmMarkModalEN from '@/locales/enUS/modals/views/medicationAdministration/confirmMarkModal.json';
import confirmUnmarkModalEN from '@/locales/enUS/modals/views/medicationAdministration/confirmUnmarkModal.json';
import medicationAdministrationHistoryEN from '@/locales/enUS/modals/views/medicationAdministration/medicationAdministrationHistory.json';
import enTMS from '@/locales/enUS/tms.json';
import functionsPageEN from '@/locales/enUS/views/functionsPage/functionsPage.json';
import medicationAdministrationEN from '@/locales/enUS/views/medicationAdministration/medicationAdministration.json';
import reportsEN from '@/locales/enUS/views/reports/reports.json';
import activitiesWidgetEN from '@/locales/enUS/widgets/activitiesWidget.json';
import favoritePersonWidgetEN from '@/locales/enUS/widgets/favoritePersonWidget.json';

export const defaultNS = 'tms';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      tms: enTMS,
      activitiesWidget: activitiesWidgetEN,
      activityPersonsListModal: activityPersonsListModalEN,
      participantExpectation: participantExpectationEN,
      statusReport: statusReportEN,
      actionCourse: actionCourseEN,
      competenceAnalysis: competenceAnalysisEN,
      generalData: generalDataEN,
      specialistGeneralData: specialistGeneralDataEN,
      summaryFA: summaryFAEN,
      qualificationDocumentation: qualificationDocumentationEN,
      qualificationObjectivesModal: qualificationObjectivesModalEN,
      internshipDocumentationModal: internshipDocumentationEN,
      generalDataUpdatedDataModal: generalDataUpdatedDataModalEN,
      addICFItemsModal: addICFItemsModalEN,
      addICDCodesModal: addICDCodesModalEN,
      actionCourseCreatedDateModal: actionCourseCreatedDateModalEN,
      createIntegrationPlanDocumentsModal: createIntegrationPlanDocumentsModalEN,
      performanceProfileCharts: performanceProfileChartsEN,
      competenceAnalysisImportModal: competenceAnalysisImportModalEN,
      integrationPlan: integrationPlanEN,
      integrationPlanVersionHistoryModal: integrationPlanVersionHistoryModalEN,
      testingResult: testingResultEN,
      deleteAppliedTestProcedureModal: deleteAppliedTestProcedureModalEN,
      performanceProfile: performanceProfileEN,
      updateAppliedTestProcedureModal: updateAppliedTestProcedureModalEN,
      createAppliedTestProcedureModal: createAppliedTestProcedureModalEN,
      personInfoModal: personInfoModalEN,
      mobility: mobilityEN,
      questionnaire: questionnaireEN,
      startNewQuestionnaireModal: startNewQuestionnaireModalEN,
      selectColleagueModal: selectColleagueModalEN,
      mobilityTransportTypesModal: mobilityTransportTypesModalEN,
      personGeneralData: personGeneralDataEN,
      diagnostics: diagnosticsEN,
      codesICD: codesICDEN,
      itemsICF: itemsICFEN,
      importInternalDifferentiationModal: importInternalDifferentiationModalEN,
      addresses: addressesEN,
      displayAddressModal: displayAddressModalEN,
      deleteAddressModal: deleteAddressModalEN,
      select: selectEN,
      vocationalTrainingArea: vocationalTrainingAreaEN,
      vocationalTrainingAreaDocuments: vocationalTrainingAreaDocumentsEN,
      integrationPlanNotifications: integrationPlanNotificationsEN,
      workingTimePlanner: workingTimePlannerEN,
      workingTime: workingTimeEN,
      workingTimePredefinedVariantsModal: workingTimePredefinedVariantsModalEN,
      medication: medicationEN,
      displayMedicationModal: displayMedicationModalEN,
      stopAdministrationModal: stopAdministrationModalEN,
      startQuestionnaireModal: startQuestionnaireModalEN,
      selfAssesment: selfAssesmentEN,
      simpleQuestionnaireModal: simpleQuestionnaireModalEN,
      medicationAdministration: medicationAdministrationEN,
      confirmMarkModal: confirmMarkModalEN,
      confirmUnmarkModal: confirmUnmarkModalEN,
      functionsPage: functionsPageEN,
      organizationalUnits: organizationalUnitsEN,
      createOrganizationalUnitModal: createOrganizationalUnitModalEN,
      updateOrganizationalUnitModal: updateOrganizationalUnitModalEN,
      organizationalUnitsForms: organizationalUnitsFormsEN,
      uploadZone: uploadZoneEN,
      additionalPersonRequirement: additionalPersonRequirementEN,
      additionalPersonRequirementModal: additionalPersonRequirementModalEN,
      personRequirementTimeListModal: personRequirementTimeListModalEN,
      personRequirementAssessmentPrognosisModal: personRequirementAssessmentPrognosisModalEN,
      activityPlanning: activityPlanningEN,
      logbookAddModal: logbookAddModalEN,
      logbookViewModal: logbookViewModalEN,
      logbookEditModal: logbookEditModalEN,
      logbookListModal: logbookListModalEN,
      medicationAdministrationHistory: medicationAdministrationHistoryEN,
      completeAppointmentModal: completeAppointmentModalEN,
      diagnosticsDisabilityModal: diagnosticsDisabilityModalEN,
      efficacyAssessmentModal: efficacyAssessmentModalEN,
      recordingEffectivenessModal: recordingEffectivenessModalEN,
      legalGuardian: legalGuardianEN,
      reports: reportsEN,
      favoritePersonWidget: favoritePersonWidgetEN,
      addParticipantsModal: activityPlanningAddParticipantsModalEN,
      dialog: dialogEN,
      participationPlan: participationPlanEN,
      goalsWishes: goalsWishesEN,
      displayParticipationPlanModal: displayParticipationPlanModalEN,
      fundingCycle: fundingCycleEN,
      smartGoals: smartGoalsEN,
      displaySmartGoalModal: displaySmartGoalModalEN,
      evaluateGoals: evaluateGoalsEN,
      displayGoalCheckModal: displayGoalCheckModalEN,
      measuresCarrying: measuresCarryingEN,
      checklist: checklistEN,
      completeMeasureModal: completeMeasureModalEN,
      developmentReport: developmentReportEN,
      developmentReportPersonDetails: developmentReportPersonDetailsEN,
      developmentReportGoalsAndActivity: developmentReportGoalsAndActivityEN,
      developmentReportReviewSupport: developmentReportReviewSupportEN,
      developmentReportVisionAndGoals: developmentReportVisionAndGoalsEN,
      developmentReportVersionHistoryModal: developmentReportVersionHistoryModalEN,
      medicalInformation: medicalInformationEN,
      typeOfDisability: typeOfDisabilityEN,
      disabilityCharacteristics: disabilityCharacteristicsEN,
      diagnosticsSpecialFeatures: diagnosticsSpecialFeaturesEN,
      serialAppointmentModal: serialAppointmentModalEN,
      addMeasuresModal: addMeasuresModalEN,
      confirmUnpinActivityModal: confirmUnpinActivityModalEN,
      confirmDeleteSmartGoalModal: confirmDeleteSmartGoalModalEN,
      confirmDeleteMeasureModal: confirmDeleteMeasureModalEN,
      datepicker: datepickerEN,
    },
    de: {
      tms: deTMS,
      activitiesWidget: activitiesWidgetDE,
      activityPersonsListModal: activityPersonsListModalDE,
      participantExpectation: participantExpectationDE,
      summaryFA: summaryFADE,
      competenceAnalysis: competenceAnalysisDE,
      statusReport: statusReportDE,
      actionCourse: actionCourseDE,
      generalData: generalDataDE,
      specialistGeneralData: specialistGeneralDataDE,
      qualificationDocumentation: qualificationDocumentationDE,
      qualificationObjectivesModal: qualificationObjectivesModalDE,
      internshipDocumentationModal: internshipDocumentationDE,
      generalDataUpdatedDataModal: generalDataUpdatedDataModalDE,
      actionCourseCreatedDateModal: actionCourseCreatedDateModalDE,
      createIntegrationPlanDocumentsModal: createIntegrationPlanDocumentsModalDE,
      performanceProfileCharts: performanceProfileChartsDE,
      competenceAnalysisImportModal: competenceAnalysisImportModalDE,
      integrationPlan: integrationPlanDE,
      integrationPlanVersionHistoryModal: integrationPlanVersionHistoryModalDE,
      testingResult: testingResultDE,
      deleteAppliedTestProcedureModal: deleteAppliedTestProcedureModalDE,
      performanceProfile: performanceProfileDE,
      mobility: mobilityDE,
      updateAppliedTestProcedureModal: updateAppliedTestProcedureModalDE,
      createAppliedTestProcedureModal: createAppliedTestProcedureModalDE,
      questionnaire: questionnaireDE,
      personInfoModal: personInfoModalDE,
      startNewQuestionnaireModal: startNewQuestionnaireModallDE,
      selectColleagueModal: selectColleagueModalDE,
      mobilityTransportTypesModal: mobilityTransportTypesModalDE,
      personGeneralData: personGeneralDataDE,
      diagnostics: diagnosticsDE,
      addICFItemsModal: addICFItemsModalDE,
      addICDCodesModal: addICDCodesModalDE,
      codesICD: codesICDDE,
      itemsICF: itemsICFDE,
      importInternalDifferentiationModal: importInternalDifferentiationModalDE,
      addresses: addressesDE,
      displayAddressModal: displayAddressModalDE,
      deleteAddressModal: deleteAddressModalDE,
      select: selectDE,
      vocationalTrainingAreaDocuments: vocationalTrainingAreaDocumentsDE,
      vocationalTrainingArea: vocationalTrainingAreaDE,
      integrationPlanNotifications: integrationPlanNotificationsDE,
      workingTimePlanner: workingTimePlannerDE,
      workingTime: workingTimeDE,
      workingTimePredefinedVariantsModal: workingTimePredefinedVariantsModalDE,
      medication: medicationDE,
      displayMedicationModal: displayMedicationModalDE,
      stopAdministrationModal: stopAdministrationModalDE,
      startQuestionnaireModal: startQuestionnaireModalDE,
      selfAssesment: selfAssesmentDE,
      simpleQuestionnaireModal: simpleQuestionnaireModalDE,
      medicationAdministration: medicationAdministrationDE,
      confirmMarkModal: confirmMarkModalDE,
      confirmUnmarkModal: confirmUnmarkModalDE,
      functionsPage: functionsPageDE,
      organizationalUnits: organizationalUnitsDE,
      createOrganizationalUnitModal: createOrganizationalUnitModalDE,
      updateOrganizationalUnitModal: updateOrganizationalUnitModalDE,
      organizationalUnitsForms: organizationalUnitsFormsDE,
      uploadZone: uploadZoneDE,
      additionalPersonRequirement: additionalPersonRequirementDE,
      additionalPersonRequirementModal: additionalPersonRequirementModalDE,
      personRequirementTimeListModal: personRequirementTimeListModalDE,
      personRequirementAssessmentPrognosisModal: personRequirementAssessmentPrognosisModalDE,
      logbookAddModal: logbookAddModalDE,
      logbookViewModal: logbookViewModalDE,
      logbookEditModal: logbookEditModalDE,
      logbookListModal: logbookListModalDE,
      activityPlanning: activityPlanningDE,
      medicationAdministrationHistory: medicationAdministrationHistoryDE,
      completeAppointmentModal: completeAppointmentModalDE,
      diagnosticsDisabilityModal: diagnosticsDisabilityModalDE,
      efficacyAssessmentModal: efficacyAssessmentModalDE,
      recordingEffectivenessModal: recordingEffectivenessModalDE,
      legalGuardian: legalGuardianDE,
      reports: reportsDE,
      favoritePersonWidget: favoritePersonWidgetDE,
      addParticipantsModal: activityPlanningAddParticipantsModalDE,
      dialog: dialogDE,
      participationPlan: participationPlanDE,
      goalsWishes: goalsWishesDE,
      displayParticipationPlanModal: displayParticipationPlanModalDE,
      fundingCycle: fundingCycleDE,
      smartGoals: smartGoalsDE,
      displaySmartGoalModal: displaySmartGoalModalDE,
      evaluateGoals: evaluateGoalsDE,
      displayGoalCheckModal: displayGoalCheckModalDE,
      measuresCarrying: measuresCarryingDE,
      checklist: checklistDE,
      completeMeasureModal: completeMeasureModalDE,
      developmentReport: developmentReportDE,
      developmentReportPersonDetails: developmentReportPersonDetailsDE,
      developmentReportGoalsAndActivity: developmentReportGoalsAndActivityDE,
      developmentReportReviewSupport: developmentReportReviewSupportDE,
      developmentReportVisionAndGoals: developmentReportVisionAndGoalsDE,
      developmentReportVersionHistoryModal: developmentReportVersionHistoryModalDE,
      medicalInformation: medicalInformationDE,
      typeOfDisability: typeOfDisabilityDE,
      disabilityCharacteristics: disabilityCharacteristicsDE,
      diagnosticsSpecialFeatures: diagnosticsSpecialFeaturesDE,
      serialAppointmentModal: serialAppointmentModalDE,
      addMeasuresModal: addMeasuresModalDE,
      confirmUnpinActivityModal: confirmUnpinActivityModalDE,
      confirmDeleteSmartGoalModal: confirmDeleteSmartGoalModalDE,
      confirmDeleteMeasureModal: confirmDeleteMeasureModalDE,
      datepicker: datepickerDE,
    },
  },
  fallbackLng: 'de',
});

export default i18n;
