import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileModalsActions } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/performancePrfoileModalSlice';
import { getLetter } from '@/core/utils/commonUtils';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson } from '@/types/person';

import {
  ICompetenceCharts,
  ICompetenceChartsPayload,
  ICompetenceChartsVariant,
  ICompetenceReview,
  ICompetenceReviewItem,
  IHistory,
  IHistoryFetchPayload,
  IQuestionnaire,
  IQuestionnaireCompletePayload,
  IQuestionnaireFetchPayload,
  IQuestionnaireQuestion,
  IQuestionnaireQuestionsGroup,
  IQuestionnaireSendNotificationPayload,
  IQuestionnaireStartNewPayload,
  IQuestionnaireUpdatePayload,
  ITargetCatalogsFetchPayload,
  IUnlockQuestionnairePayload,
  performanceProfileActions,
} from './slice';

type HistoryResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/history',
  'get',
  '200'
>;
type CompetenciesCharts = OASOutput<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/chart_data',
  'get',
  '200'
>;

type QuestionnaireResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/details',
  'get',
  '200'
>;
type QuestionnaireUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/details',
  'put'
>;

type TargetCatalogTypesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/performance_profile/target_catalog_type',
  'get',
  '200'
>;

type QuestionnaireStartRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/start',
  'post'
>;

type QuestionnaireFinishRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/finish',
  'post'
>;

type QuestionnaireUnlockRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/unlock',
  'post'
>;

type QuestionnaireSendNotificationRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/performance_profile/competence_analysis/send_email',
  'post'
>;

interface ICompetenceChartsVariantResponse {
  id: number;
  name: string;
  has_childs: boolean;
}

const mapChartVariants = (
  chartVariants: ICompetenceChartsVariantResponse[]
): ICompetenceChartsVariant[] => {
  return chartVariants.reduce((accum: ICompetenceChartsVariant[], chartVariant) => {
    const newChartVariant: ICompetenceChartsVariant = {
      name: chartVariant.name,
      id: chartVariant.id,
      hasChilds: chartVariant.has_childs,
    };

    accum.push(newChartVariant);

    return accum;
  }, []);
};

const addIndexToCompetencies = (response: CompetenciesCharts) => {
  return response.data.reduce((accum: ICompetenceReviewItem[], data) => {
    const reviewKeys = Object.keys(data.reviews);

    const reviewsWithIndexes = reviewKeys.reduce(
      (accum: Record<string, ICompetenceReview>, reviewKey, currentIndex) => {
        return {
          ...accum,
          [reviewKey]: {
            value: data.reviews[reviewKey].value,
            index: getLetter(currentIndex),
          },
        };
      },
      {}
    );

    const newData: ICompetenceReviewItem = {
      date: data.date,
      reviews: reviewsWithIndexes,
    };

    accum.push(newData);

    return accum;
  }, []);
};

const mapQuestionnaireResponse = (response: QuestionnaireResponse): IQuestionnaire => {
  const competencies = response.competencies.map((target) => {
    const groupItem: IQuestionnaireQuestionsGroup = {
      id: target.id,
      name: target.name,
      questionsList: target.rough_targets.map((roughTarget) => {
        const question: IQuestionnaireQuestion = {
          id: roughTarget.id,
          name: roughTarget.name,
          review: roughTarget.review,
          description: roughTarget.description,
          reviews: roughTarget.reviews.map((review, index) => {
            return {
              id: index,
              review: review.review,
              description: review.description,
            };
          }),
          reviewsHistory: roughTarget.reviews_history.map((reviewHistory, index) => {
            return {
              id: index,
              review: reviewHistory.review,
              assessmentType: reviewHistory.assessment_type,
              fromTimestamp: toClientDateInput(reviewHistory.from_timestamp),
            };
          }),
        };

        return question;
      }),
    };

    return groupItem;
  });
  return {
    competencies: competencies,
  };
};

const mapQuestionnaireStatus = (responseStatus?: string) => {
  let status = null;

  if (!responseStatus) {
    return status;
  }

  if (!isEnumValue(QuestionnaireStatus, responseStatus)) {
    return status;
  }

  status = responseStatus;

  return status;
};

const mapHistoryResponse = (response: HistoryResponse): IHistory[] => {
  const mapQuestionnaireStatus = (statusText?: string | null): QuestionnaireStatus | null => {
    if (statusText === null) {
      return null;
    }

    switch (statusText) {
      case QuestionnaireStatus.Started:
        return QuestionnaireStatus.Started;
      case QuestionnaireStatus.ForApproval:
        return QuestionnaireStatus.ForApproval;
      case QuestionnaireStatus.Release:
        return QuestionnaireStatus.Release;
      default:
        return null;
    }
  };

  return response.history.map((questionnaire) => ({
    createdTimestamp: questionnaire.created_timestamp,
    closedBy: questionnaire.closed_by,
    id: questionnaire.id,
    closedTimestamp: questionnaire.created_timestamp,
    createdBy: questionnaire.created_by,
    lastStatusID: questionnaire.last_status_id,
    targetCatalogType: questionnaire.target_catalog_type,
    status: mapQuestionnaireStatus(questionnaire.status_text),
    statusText: questionnaire.status_text,
  }));
};

function* fetchHistory(
  action: PayloadAction<IHistoryFetchPayload>
): Generator<any, void, HistoryResponse> {
  yield put(performanceProfileActions.setHistoryLock(LoadingStatus.LOADING));

  const { personID } = action.payload;

  try {
    const response = yield call(
      restCall,
      '/performance_profile/competence_analysis/history',
      'get',
      {
        query: { person_id: personID },
        ...authAdd(),
      }
    );

    const history = mapHistoryResponse(response);

    yield put(performanceProfileActions.setHistory(history));

    yield put(performanceProfileActions.setHistoryLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on history fetching');
    yield put(performanceProfileActions.setHistoryLock(LoadingStatus.ERROR));
  }
}

function* fetchTargetCatalogTypes(
  action: PayloadAction<ITargetCatalogsFetchPayload>
): Generator<any, void, TargetCatalogTypesResponse> {
  const { personID } = action.payload;
  yield put(performanceProfileActions.setTargetCatalogTypesLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/performance_profile/target_catalog_type', 'get', {
      ...authAdd(),
      query: {
        person_id: personID,
      },
    });

    const targetCatalogTypes = response.target_catalog_types;

    yield put(performanceProfileActions.setTargetCatalogTypes(targetCatalogTypes));
  } catch (error) {
    console.log('Error on target catalog types fetching');
    yield put(performanceProfileActions.setTargetCatalogTypesLock(LoadingStatus.ERROR));
  }
}

function* fetchCompetenceChartsData(
  action: PayloadAction<ICompetenceChartsPayload>
): Generator<any, void, CompetenciesCharts> {
  const { personID, parentID, competenceAnalysisIds } = action.payload;

  try {
    yield put(performanceProfileActions.setCompetenceChartsDataLock(LoadingStatus.LOADING));

    const response = yield call(
      restCall,
      '/performance_profile/competence_analysis/chart_data',
      'get',
      {
        query: {
          person_id: personID,
          parent_id: parentID,
          competence_analysis_ids: competenceAnalysisIds,
        },
        ...authAdd(),
      }
    );

    const competenciesReviews: ICompetenceCharts = {
      data: addIndexToCompetencies(response),
      variants: mapChartVariants(response.variants),
      valueRange: response.value_range,
    };

    yield put(performanceProfileActions.setCompetenceChartsData(competenciesReviews));
    yield put(performanceProfileActions.setCompetenceChartsDataLock(LoadingStatus.LOADED));
  } catch (error) {
    yield put(performanceProfileActions.setCompetenceChartsData(null));
    yield put(performanceProfileActions.setCompetenceChartsDataLock(LoadingStatus.ERROR));
  }
}

function* fetchQuestionnaire(
  action: PayloadAction<IQuestionnaireFetchPayload>
): Generator<any, void, QuestionnaireResponse> {
  const { personID, competenceAnalysisID } = action.payload;

  try {
    yield put(performanceProfileActions.setQuestionnaireLock(LoadingStatus.LOADING));

    const response = yield call(
      restCall,
      '/performance_profile/competence_analysis/details',
      'get',
      {
        query: {
          person_id: personID,
          competence_analysis_id: competenceAnalysisID,
        },
        ...authAdd(),
      }
    );

    const questions = mapQuestionnaireResponse(response);
    yield put(performanceProfileActions.setQuestionnaire(questions));

    const status = mapQuestionnaireStatus(response.status);
    yield put(performanceProfileActions.setQuestionnaireStatus(status));

    yield put(performanceProfileActions.setQuestionnaireLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log(error);
    yield put(performanceProfileActions.setQuestionnaireLock(LoadingStatus.ERROR));
  }
}

function* updateQuestionnaire(action: PayloadAction<IQuestionnaireUpdatePayload>) {
  const { competence } = action.payload;
  const selectedPerson: IPerson = yield select(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson.id;

  const detailsIDs: Record<number, number[]> = yield select(performanceProfileSelectors.detailsIDs);

  if (!(selectedPersonID in detailsIDs)) {
    return;
  }

  const competenceAnalysisID = detailsIDs[selectedPersonID][0];
  const personID = selectedPerson.id;

  try {
    yield put(performanceProfileActions.setUpdateQuestionnaireLock(LoadingStatus.LOADING));

    const request: QuestionnaireUpdateRequest = {
      query: {
        person_id: personID,
        competence_analysis_id: competenceAnalysisID,
      },
      json: {
        rough_targets: [competence],
      },
      ...authAdd(),
    };

    const response: string = yield call(
      restCall,
      '/performance_profile/competence_analysis/details',
      'put',
      request
    );

    const questionnaireStatus: QuestionnaireStatus = yield select(
      performanceProfileSelectors.questionnaireStatus
    );

    if (questionnaireStatus !== response) {
      const status = mapQuestionnaireStatus(response);
      yield put(performanceProfileActions.setQuestionnaireStatus(status));
    }

    yield put(performanceProfileActions.setUpdateQuestionnaireLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on questionnaire update', error);
    yield put(performanceProfileActions.setUpdateQuestionnaireLock(LoadingStatus.ERROR));
  }
}

function* startNewQuestionnaire(action: PayloadAction<IQuestionnaireStartNewPayload>): any {
  const { personID } = action.payload;

  try {
    const request: QuestionnaireStartRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/performance_profile/competence_analysis/start',
      'post',
      {
        ...request,
      },
      null,
      true
    );
    const responseBody: number = yield response.json();
    yield put(
      performanceProfileActions.setViewDetailsIDs({
        personID: personID,
        competenceAnalysisIDs: [responseBody],
      })
    );
    yield put(
      performanceProfileActions.setViewDetailsViewMode({
        personID: personID,
        viewMode: PerformanceProfileViews.Questionnaire,
      })
    );

    yield put(performanceProfileModalsActions.closeSelectTargetCatalogModal());
  } catch (error) {
    console.log('Error on start new questionnaire', error);
  }
}

function* completeQuestionnaire(action: PayloadAction<IQuestionnaireCompletePayload>): any {
  const { competenceAnalysisID, personID } = action.payload;

  yield put(performanceProfileActions.setCompleteQuestionnaireLock(LoadingStatus.LOADING));

  try {
    const request: QuestionnaireFinishRequest = {
      query: {
        competence_analysis_id: competenceAnalysisID,
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/performance_profile/competence_analysis/finish',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    const responseBody = yield response.json();

    const isAllowed = responseBody.competence_analysis_id && responseBody.status;
    const listOfUsers = responseBody.users;

    yield put(performanceProfileActions.setCompleteQuestionnaireLock(LoadingStatus.LOADED));

    if (isAllowed) {
      yield put(
        performanceProfileActions.setViewDetailsViewMode({
          personID: personID,
          viewMode: PerformanceProfileViews.QuestionnaireHistory,
        })
      );
    } else if (!isAllowed && listOfUsers) {
      yield put(
        performanceProfileModalsActions.openSelectColleagueModal({
          users: listOfUsers,
        })
      );
    }
  } catch (error) {
    console.log('Error on finish questionnaire', error);
    yield put(performanceProfileActions.setCompleteQuestionnaireLock(LoadingStatus.ERROR));
  }
}

function* unlockQuestionnaire(action: PayloadAction<IUnlockQuestionnairePayload>) {
  const { competenceAnalysisID, personID } = action.payload;

  try {
    const request: QuestionnaireUnlockRequest = {
      query: {
        competence_analysis_id: competenceAnalysisID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/performance_profile/competence_analysis/unlock',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(performanceProfileActions.fetchHistory({ personID }));
  } catch (error) {
    console.log('Error on unlock questionnaire', error);
  }
}

function* sendNotification(action: PayloadAction<IQuestionnaireSendNotificationPayload>) {
  const { competenceAnalysisID, usersIds } = action.payload;

  try {
    const request: QuestionnaireSendNotificationRequest = {
      query: {
        competence_analysis_id: competenceAnalysisID,
        users_ids: usersIds,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/performance_profile/competence_analysis/finish',
      'post',
      {
        ...request,
      },
      null,
      true
    );
  } catch (error) {
    console.log('Error on send notification', error);
  }
}

export const performanceProfileSagas = [
  takeLatest(performanceProfileActions.fetchHistory, fetchHistory),
  takeLatest(performanceProfileActions.fetchTargetCatalogTypes, fetchTargetCatalogTypes),
  takeLatest(performanceProfileActions.fetchCompetenceChartsData, fetchCompetenceChartsData),
  takeLatest(performanceProfileActions.fetchQuestionnaire, fetchQuestionnaire),
  takeLatest(performanceProfileActions.updateQuestionnaire, updateQuestionnaire),
  takeLatest(performanceProfileActions.startNewQuestionnaire, startNewQuestionnaire),
  takeLatest(performanceProfileActions.completeQuestionnaire, completeQuestionnaire),
  takeLatest(performanceProfileActions.unlockQuestionnaire, unlockQuestionnaire),
  takeLatest(performanceProfileActions.sendNotification, sendNotification),
];
