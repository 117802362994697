import React from 'react';

import { IIcon } from '@/types/icon';

export const EmptyGoalsIcon: React.FC<IIcon> = ({ className }) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.5625 25.0617C13.5625 24.4209 14.0771 23.9009 14.7155 23.8908C14.9191 22.2288 15.4222 20.6582 16.1689 19.2344H14.7344C14.0872 19.2344 13.5625 18.7097 13.5625 18.0625C13.5625 17.4153 14.0872 16.8906 14.7344 16.8906H17.7233C20.2259 13.8481 24.5 11.7817 29.25 11.8911V6C29.25 4.34315 27.9069 3 26.25 3H9.5H3C1.34315 3 0 4.34315 0 6V31.5469C0 33.2037 1.34315 34.5469 3 34.5469H17.9886C16.0245 32.2986 14.7827 29.4039 14.6297 26.2283C14.0316 26.1752 13.5625 25.6735 13.5625 25.0617ZM22.1562 9.85938H14.7344C14.0871 9.85938 13.5625 10.3841 13.5625 11.0312C13.5625 11.6784 14.0872 12.2031 14.7344 12.2031H22.1562C22.8034 12.2031 23.3281 11.6784 23.3281 11.0312C23.3281 10.3841 22.8034 9.85938 22.1562 9.85938ZM9.70359 27.0622L12.0083 24.7575C12.4659 24.2999 12.4659 23.5579 12.0084 23.1002C11.5507 22.6427 10.8087 22.6427 10.3511 23.1002L8.875 24.5763L8.53172 24.233C8.07406 23.7755 7.33203 23.7755 6.87445 24.233C6.4168 24.6907 6.4168 25.4327 6.87445 25.8903L8.04633 27.0622C8.50398 27.5198 9.24594 27.5198 9.70359 27.0622ZM9.70359 20.063L12.0083 17.7583C12.4659 17.3007 12.4659 16.5587 12.0084 16.101C11.5507 15.6434 10.8087 15.6434 10.3511 16.101L8.875 17.5771L8.53172 17.2338C8.07406 16.7763 7.33203 16.7763 6.87445 17.2338C6.4168 17.6915 6.4168 18.4335 6.87445 18.8911L8.04633 20.063C8.50398 20.5206 9.24594 20.5206 9.70359 20.063ZM9.70359 12.9536L12.0083 10.6489C12.4659 10.1913 12.4659 9.4493 12.0084 8.99164C11.5507 8.53398 10.8087 8.53398 10.3511 8.99164L8.875 10.4677L8.53172 10.1245C8.07406 9.6668 7.33203 9.6668 6.87445 10.1245C6.4168 10.5821 6.4168 11.3241 6.87445 11.7817L8.04633 12.9536C8.50398 13.4113 9.24602 13.4113 9.70359 12.9536Z'
      fill='#838FAF'
    />
    <path
      d='M30.0942 22.1001C29.5539 21.8172 28.9401 21.6562 28.2891 21.6562C26.1352 21.6562 24.3828 23.4086 24.3828 25.5625C24.3828 27.7164 26.1352 29.4688 28.2891 29.4688C30.443 29.4688 32.1953 27.7164 32.1953 25.5625C32.1953 24.9115 32.0344 24.2977 31.7515 23.7573L29.1177 26.3912C28.6599 26.8488 27.918 26.8488 27.4604 26.3912C27.0027 25.9336 27.0027 25.1916 27.4604 24.7339L30.0942 22.1001Z'
      fill='#838FAF'
    />
    <path
      d='M35.9607 24.5283L34.3286 23.9842C35.3666 27.9552 32.3495 31.8138 28.2813 31.8138C24.835 31.8138 22.0312 29.0101 22.0312 25.5638C22.0312 21.4925 25.8934 18.4794 29.8609 19.5165L29.3171 17.8851C28.9066 16.6537 29.2008 15.3228 30.0808 14.3792C23.2196 13.2793 16.9531 18.5837 16.9531 25.5638C16.9531 31.8101 22.0349 36.8919 28.2813 36.8919C34.5276 36.8919 39.6094 31.8101 39.6094 25.5638C39.6094 24.952 39.5599 24.3516 39.4661 23.7658C38.4864 24.6791 37.134 24.919 35.9607 24.5283Z'
      fill='#838FAF'
    />
    <path
      d='M39.3233 16.6809L37.0467 15.9221L36.2879 13.6456C36.061 12.965 35.1949 12.7594 34.6867 13.2676L31.2676 16.6866C31.0086 16.9456 30.9182 17.3287 31.034 17.6762L31.9457 20.4114C32.042 20.7001 32.2685 20.9268 32.5573 21.023C35.4054 21.9548 35.3212 21.9844 35.5982 21.9844C35.8507 21.9844 36.0978 21.8855 36.2821 21.7011L39.7011 18.2821C40.2086 17.7749 40.005 16.9082 39.3233 16.6809Z'
      fill='#838FAF'
    />
  </svg>
);
