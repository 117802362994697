import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { medicationActions } from '@/core/redux/slices/functions/medication/medicationSlice';
import { medicationSelectors } from '@/core/redux/slices/functions/medication/selectors';
import { medicationModalsActions } from '@/core/redux/slices/modalsSlice/functions/medication/medicationModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

import { MedicationList } from './MedicationList/MedicationList';

export const Medication: React.FC = () => {
  const { t: medicationTranslations } = useTranslation('medication');

  const [selectedMedicationID, setSelectedMedicationID] = useState<number | null>(null);

  const medications = useAppSelector(medicationSelectors.medications);
  const downloadLoadingStatus = useAppSelector(medicationSelectors.downloadMedicationDocumentLock);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const personID = selectedPerson?.id;

  const dispatch = useAppDispatch();

  const selectedMedication = useMemo(
    () => medications.find((item) => item.id === selectedMedicationID),
    [medications, selectedMedicationID]
  );

  const openMedication = (medicationID: number) => {
    if (!personID) {
      return;
    }

    dispatch(
      medicationModalsActions.openDisplayMedicationModal({
        medicationID: medicationID,
        personID: personID,
      })
    );
  };

  const stopAdministrating = (medicationID: number) => {
    if (!personID) {
      return;
    }

    dispatch(
      medicationModalsActions.openStopAdministratingModal({
        medicationID: medicationID,
        personID: personID,
      })
    );
  };

  const handleDownloadMedicationDocument = () => {
    if (!selectedMedicationID) {
      return;
    }

    dispatch(
      medicationActions.downloadMedicationDocument({
        medicationID: selectedMedicationID,
      })
    );
  };

  return (
    <div className={'h-full flex flex-col gap-3 overflow-hidden'}>
      <MedicationList
        personID={personID}
        medications={medications}
        setSelectedMedicationID={setSelectedMedicationID}
      />
      <div className={'flex gap-3'}>
        <Button onClick={() => openMedication(0)}>
          {medicationTranslations('buttons.create.label')}
        </Button>
        {selectedMedicationID && (
          <>
            <Button onClick={() => openMedication(selectedMedicationID)}>
              {medicationTranslations('buttons.update.label')}
            </Button>
            <Button
              onClick={handleDownloadMedicationDocument}
              disabled={downloadLoadingStatus === LoadingStatus.LOADING}
            >
              {medicationTranslations('buttons.download.label')}
            </Button>
            {selectedMedication?.isNotDispensed && (
              <Button onClick={() => stopAdministrating(selectedMedicationID)}>
                {medicationTranslations('buttons.delete.label')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
