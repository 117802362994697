import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { ISmartGoalFormFields, SmartGoalForm } from '@/components/forms/SmartGoal/SmartGoal';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { SchemaProvider } from '@/components/SchemaContext/SchemaContext';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { smartGoalsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { SmartGoalFormResolver } from './helpers/resolver';

const ModalContent: React.FC = () => {
  const form = useForm<ISmartGoalFormFields>({
    resolver: yupResolver(SmartGoalFormResolver),
  });
  const dispatch = useAppDispatch();
  const { t: smartGoalModalTranslations } = useTranslation('displaySmartGoalModal');

  // selectors
  const { payload } = useAppSelector(smartGoalsModalsSelectors.displaySmartGoalModal);
  const smartGoalDetails = useAppSelector(smartGoalsSelectors.smartGoalDetails);
  // locks
  const createSmartGoalLock = useAppSelector(smartGoalsSelectors.createSmartGoalLock);
  const updateSmartGoalLock = useAppSelector(smartGoalsSelectors.updateSmartGoalLock);
  const smartGoalDetailsLock = useAppSelector(smartGoalsSelectors.smartGoalDetailsLock);

  // loading state
  const isLoading =
    createSmartGoalLock === LoadingStatus.LOADING || updateSmartGoalLock === LoadingStatus.LOADING;
  const isLoaded =
    createSmartGoalLock === LoadingStatus.LOADED || updateSmartGoalLock === LoadingStatus.LOADED;

  // payload data
  const personID = payload?.personID;
  const smartGoalID = payload?.smartGoalID;

  // handle exit

  const handleExit = () => {
    dispatch(smartGoalsModalActions.closeDisplaySmartGoalModal());
  };

  // handle submit

  const onSubmit = (data: ISmartGoalFormFields) => {
    if (!personID || smartGoalID === undefined || isLoading) {
      return;
    }

    if (smartGoalID === 0) {
      dispatch(
        smartGoalsActions.createSmartGoal({
          personID: personID,
          smartGoalData: data,
        })
      );
    } else {
      dispatch(
        smartGoalsActions.updateSmartGoal({
          personID: personID,
          smartGoalID: smartGoalID,
          smartGoalData: data,
        })
      );
    }
  };

  // fetch details

  useEffect(() => {
    if (smartGoalID === undefined || smartGoalID === 0) {
      return;
    }

    dispatch(
      smartGoalsActions.fetchSmartGoalDetails({
        smartGoalID: smartGoalID,
      })
    );
  }, [smartGoalID]);

  // reset form values on details loaded

  useEffect(() => {
    if (!smartGoalDetails || smartGoalDetailsLock !== LoadingStatus.LOADED) {
      return;
    }

    form.reset({
      ...smartGoalDetails,
      name: smartGoalDetails.name ?? '',
    });
  }, [smartGoalDetails, smartGoalDetailsLock]);

  // exit on update

  useEffect(() => {
    if (isLoaded) {
      handleExit();
    }
  }, [isLoaded]);

  // reset state on unmount

  useEffect(() => {
    return () => {
      dispatch(smartGoalsActions.setSmartGoalDetails(null));
      dispatch(smartGoalsActions.setSmartGoalDetailsLock(LoadingStatus.NEVER));
      dispatch(smartGoalsActions.setUpdateSmartGoalLock(LoadingStatus.NEVER));
      dispatch(smartGoalsActions.setCreateSmartGoalLock(LoadingStatus.NEVER));
    };
  }, []);

  const renderFooter = (
    <div className='flex gap-4'>
      <Button
        type='submit'
        onClick={form.handleSubmit(onSubmit)}
        buttonVariant={form.formState.isValid ? 'Default' : 'Disabled'}
      >
        {smartGoalModalTranslations('buttons.submit.label')}
      </Button>
      <Button type='button' buttonVariant='Secondary' onClick={handleExit}>
        {smartGoalModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar onExit={handleExit} className='w-[800px]' footer={renderFooter} position={'right'}>
      <SchemaProvider schema={SmartGoalFormResolver}>
        <FormProvider {...form}>
          <div className={'relative p-7'}>
            <SmartGoalForm className={isLoading ? 'opacity-50' : ''} />
            {isLoading && (
              <div className={'absolute inset-0 flex items-center justify-center'}>
                <SpinnerIcon className={`text-${colorTypes.Blue} w-fit`} />
              </div>
            )}
          </div>
        </FormProvider>
      </SchemaProvider>
    </Sidebar>
  );
};

export const DisplaySmartGoalModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(smartGoalsModalsSelectors.displaySmartGoalModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
