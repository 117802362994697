import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OffsetOptions, Placement } from '@floating-ui/react';
import { motion } from 'framer-motion';

import { Dropdown } from '@/components/Dropdown/Dropdown';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { ISelectItem, SelectItem } from '@/components/Select/SelectItem/SelectItem';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { CrossIcon } from '../Icons/CrossIcon';
import { Typography } from '../Typography/Typography';

import { getSelectVariant, SelectVariants } from './helpers/selectVariants';

export interface ISelect {
  contentOffset?: OffsetOptions;
  values?: ISelectItem[];
  selectedComponent?: React.ReactNode;
  placeholder?: React.ReactNode;
  onChange?: (id: string) => void;
  initialValueID?: string | null;
  className?: string;
  contentPosition?: Placement;
  contentClassName?: string;
  dropdownClassName?: string;
  selectedContainerClassName?: string;
  iconClassName?: string;
  showSelected?: boolean;
  variant?: SelectVariants;
  readOnly?: boolean;
  showExpandIcon?: boolean;
  isFlexible?: boolean;
  setSelectedExternal?: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedExternal?: string | null;
  isResetable?: boolean;
  onReset?: () => void;
  isOpen?: boolean;
  initialScrollToValue?: string | null;
  label?: string | React.ReactNode;
  isRequired?: boolean;
  wrapperClassname?: string;
}

export const Select: React.FC<ISelect> = ({
  values,
  className,
  onChange,
  contentClassName,
  dropdownClassName,
  selectedContainerClassName,
  iconClassName,
  selectedComponent,
  selectedExternal,
  setSelectedExternal,
  contentOffset,
  onReset,
  wrapperClassname,
  initialScrollToValue = null,
  showSelected = true,
  initialValueID = null,
  variant = 'Input',
  contentPosition = 'bottom-start',
  placeholder,
  readOnly = false,
  showExpandIcon = true,
  isFlexible = false,
  isResetable = false,
  isOpen = false,
  label,
  isRequired = false,
}) => {
  const { t: selectTranslations } = useTranslation('select');

  const [selectedItem, setSelectedItem] = useState<string | null | undefined>(initialValueID);
  const [expanded, setExpanded] = useState<boolean>(isOpen);

  const [scrollToLetter, setScrollToLetter] = useState<string | null>(initialScrollToValue);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const selected = selectedExternal || selectedItem;
  const setSelected = setSelectedExternal || setSelectedItem;

  const currentItem = values?.find((selectItem) => {
    return String(selectItem.id) === String(selected);
  });
  const isSelected = currentItem !== null && currentItem !== undefined;

  useEffect(() => {
    setSelected(initialValueID ? String(initialValueID) : undefined);
  }, [initialValueID]);

  useEffect(() => {
    setExpanded(isOpen);
  }, [isOpen]);

  const handleChange = (id: string) => {
    setSelected(id);
    onChange?.(id);
    setExpanded(false);
  };

  const handleExpand = () => {
    if (readOnly) {
      return;
    }

    setExpanded((prev) => !prev);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleExpand();
    }

    if (event.key.match(/[a-zA-Z]/)) {
      setScrollToLetter(event.key.toLowerCase());
    }
  };

  const handleReset = (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    e.stopPropagation();

    setSelected(undefined);
    onReset?.();
  };

  const renderContent = useCallback(
    () => (
      <div className={twMerge('p-2 overflow-hidden', contentClassName)}>
        <div className='max-h-[340px] overflow-auto'>
          <div className='p-1'>
            {values && values.length !== 0 ? (
              values.map((selectItem) => (
                <SelectItem
                  id={selectItem.id}
                  component={selectItem.component}
                  selected={selectItem.id === selected}
                  onClick={handleChange}
                  componentContainerClassName={selectItem.componentContainerClassName}
                  showCheckIcon={selectItem.showCheckIcon}
                  key={selectItem.id}
                />
              ))
            ) : (
              <Typography>{selectTranslations('empty.label')}</Typography>
            )}
          </div>
        </div>
      </div>
    ),
    [values, selected, contentClassName, contentPosition]
  );

  useEffect(() => {
    if (scrollToLetter && dropdownRef.current) {
      const firstItemStartingWithLetter = values?.find((item) => {
        if (typeof item.component === 'string') {
          return item.component.toLowerCase().startsWith(scrollToLetter);
        }
      });

      if (firstItemStartingWithLetter) {
        // Scroll to the first item starting with the specified letter
        const itemElement = document.querySelector(`[data-id="${firstItemStartingWithLetter.id}"]`);

        if (itemElement) {
          itemElement.scrollIntoView({ behavior: 'smooth' });
        }
      }

      setScrollToLetter(null);
    }
  }, [scrollToLetter]);

  useEffect(() => {
    if (expanded && initialScrollToValue && dropdownRef.current) {
      const initialScrollItem = values?.find((item) => item.id === initialScrollToValue);
      if (initialScrollItem) {
        const itemElement = document.querySelector(`[data-id="${initialScrollItem.id}"]`);
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: 'instant' });
        }
      }
    }
  }, [expanded, initialScrollToValue, values]);

  return (
    <div className={twMerge('flex flex-col gap-3', wrapperClassname)}>
      {label && (
        <>
          {typeof label === 'string' ? (
            <Typography as='label'>
              {label} {isRequired && '*'}
            </Typography>
          ) : (
            <>
              {label} {isRequired && '*'}
            </>
          )}
        </>
      )}
      <div className={className} ref={dropdownRef} onKeyDown={handleKeyDown}>
        <Dropdown
          content={renderContent()}
          position={contentPosition}
          open={expanded}
          setOpen={setExpanded}
          className={dropdownClassName}
          preventClick={readOnly}
          isFlexible={isFlexible}
          floatClassName='rounded-default bg-white'
          contentOffset={contentOffset}
        >
          <div
            className={twMerge(
              'flex flex-row justify-between gap-2 w-full relative items-center',
              getSelectVariant(variant),
              expanded && `border-${colorTypes.Blue}`,
              selectedContainerClassName
            )}
            onClick={handleExpand}
            role={'button'}
            tabIndex={0}
          >
            <span className={twMerge('truncate', !isSelected && `text-${colorTypes.Disabled}`)}>
              {showSelected && selectedComponent
                ? selectedComponent
                : currentItem && currentItem.component}
              {!isSelected && showSelected && placeholder
                ? placeholder
                : selectTranslations('placeholder.label')}
            </span>
            <div className={'flex flex-row gap-2 justify-end'}>
              {isResetable && selected && (
                <CrossIcon
                  className={`flex items-center justify-center text-disabled w-5 h-5 min-h-[20px] min-w-[20px]`}
                  onClick={handleReset}
                />
              )}
              {showExpandIcon && (
                <motion.div
                  className={twMerge(
                    'flex items-center justify-center text-disabled',
                    iconClassName
                  )}
                  animate={{ rotate: expanded ? 180 : 0 }}
                >
                  <ArrowIcon />
                </motion.div>
              )}
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
