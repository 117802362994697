import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { Camelize } from '@/types/camelize';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IMeasureAddPayload,
  IMeasureDeletePayload,
  IMeasuresAvailableFetchPayload,
  IMeasuresFetchPayload,
  ISmartGoalCompetenciesFetchPayload,
  ISmartGoalCreatePayload,
  ISmartGoalDeletePayload,
  ISmartGoalDetailsFetchPayload,
  ISmartGoalsFetchPayload,
  ISmartGoalUpdatePayload,
  smartGoalsActions,
} from './smartGoalsSlice';

// types

type SmartGoalsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal',
  'get',
  '200'
>;
type SmartGoalDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/details',
  'get',
  '200'
>;
type SmartGoalCompetenciesResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/competencies',
  'get',
  '200'
>;
type SmartGoalCreateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal',
  'post'
>;
type SmartGoalUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal',
  'put'
>;
type SmartGoalDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal',
  'delete'
>;

type MeasuresResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/measure',
  'get',
  '200'
>;
type MeasuresAvailableResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/measure/available',
  'get',
  '200'
>;
type MeasureDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/measure',
  'delete'
>;
type MeasureAddRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/funding_cycle/smart_goal/measure',
  'post'
>;

// sagas

function* fetchSmartGoals(
  action: PayloadAction<ISmartGoalsFetchPayload>
): Generator<any, void, SmartGoalsResponse> {
  const { personID, isClosed } = action.payload;

  yield put(smartGoalsActions.setSmartGoalsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal', 'get', {
      query: {
        person_id: personID,
        is_closed: isClosed,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<SmartGoalsResponse> = toCamelCase(response);
    const smartGoals = camelizeResponse.smartGoals.map((item) => ({
      ...item,
      agreedOnDate: toClientDateInput(item.agreedOnDate) ?? '',
      nextReviewDate: toClientDateInput(item.nextReviewDate) ?? '',
    }));

    yield put(smartGoalsActions.setSmartGoals(smartGoals));
    yield put(smartGoalsActions.setSmartGoalsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on smart goals fetching');
    yield put(smartGoalsActions.setSmartGoalsLock(LoadingStatus.ERROR));
  }
}

function* fetchSmartGoalDetails(
  action: PayloadAction<ISmartGoalDetailsFetchPayload>
): Generator<any, void, SmartGoalDetailsResponse> {
  const { smartGoalID } = action.payload;

  yield put(smartGoalsActions.setSmartGoalDetailsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal/details', 'get', {
      query: {
        smart_goal_id: smartGoalID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<SmartGoalDetailsResponse> = toCamelCase(response);
    const smartGoalDetails = {
      ...camelizeResponse.smartGoal,
      agreedOnDate: toClientDateInput(camelizeResponse.smartGoal.agreedOnDate) ?? '',
      firstReviewDate: toClientDateInput(camelizeResponse.smartGoal.firstReviewDate) ?? '',
    };

    yield put(smartGoalsActions.setSmartGoalDetails(smartGoalDetails));

    yield put(smartGoalsActions.setSmartGoalDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on smart goal details fetching');
    yield put(smartGoalsActions.setSmartGoalDetailsLock(LoadingStatus.ERROR));
  }
}

function* createSmartGoal(
  action: PayloadAction<ISmartGoalCreatePayload>
): Generator<any, void, any> {
  const { personID, smartGoalData } = action.payload;
  const { name, agreedOnDate, firstReviewDate } = smartGoalData;

  yield put(smartGoalsActions.setCreateSmartGoalLock(LoadingStatus.LOADING));

  try {
    const request: SmartGoalCreateRequest = {
      json: {
        person_id: personID,
        name: name,
        agreed_on_date: toBackendDate(agreedOnDate) ?? '',
        first_review_date: toBackendDate(firstReviewDate) ?? '',
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(smartGoalsActions.setCreateSmartGoalLock(LoadingStatus.LOADED));

    yield put(
      smartGoalsActions.fetchSmartGoals({
        personID: personID,
      })
    );
  } catch (error) {
    console.error('Error on create smart goal', error);
    yield put(smartGoalsActions.setCreateSmartGoalLock(LoadingStatus.ERROR));
  }
}

function* updateSmartGoal(
  action: PayloadAction<ISmartGoalUpdatePayload>
): Generator<any, void, any> {
  const { personID, smartGoalID, smartGoalData } = action.payload;
  const { name, agreedOnDate, firstReviewDate } = smartGoalData;

  yield put(smartGoalsActions.setUpdateSmartGoalLock(LoadingStatus.LOADING));

  try {
    const request: SmartGoalUpdateRequest = {
      json: {
        id: smartGoalID,
        name: name,
        agreed_on_date: toBackendDate(agreedOnDate) ?? '',
        first_review_date: toBackendDate(firstReviewDate) ?? '',
        repetition_months_number: 0,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal',
      'put',
      {
        ...request,
      },
      null,
      true
    );

    yield put(smartGoalsActions.setUpdateSmartGoalLock(LoadingStatus.LOADED));

    yield put(
      smartGoalsActions.fetchSmartGoals({
        personID: personID,
      })
    );
  } catch (error) {
    console.error('Error on update smart goal', error);
    yield put(smartGoalsActions.setCreateSmartGoalLock(LoadingStatus.ERROR));
  }
}

function* deleteSmartGoal(
  action: PayloadAction<ISmartGoalDeletePayload>
): Generator<any, void, any> {
  const { smartGoalID, personID } = action.payload;

  try {
    const request: SmartGoalDeleteRequest = {
      query: {
        smart_goal_id: smartGoalID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal',
      'delete',
      {
        ...request,
      },
      null,
      true
    );

    yield put(
      smartGoalsActions.fetchSmartGoals({
        personID: personID,
      })
    );

    yield put(smartGoalsActions.setSelectedSmartGoal(undefined));
  } catch (error) {
    console.error('Error on delete smart goal', error);
  }
}

function* fetchMeasures(
  action: PayloadAction<IMeasuresFetchPayload>
): Generator<any, void, MeasuresResponse> {
  const { smartGoalID, isFinished } = action.payload;

  yield put(smartGoalsActions.setMeasuresLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal/measure', 'get', {
      query: {
        smart_goal_id: smartGoalID,
        is_finished: isFinished,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<MeasuresResponse> = toCamelCase(response);
    const measures = camelizeResponse.measures.map((item) => ({
      ...item,
      nearestActivityTimestamp: toClientDateInput(item.nearestActivityTimestamp),
      lastActivityTimestamp: toClientDateInput(item.lastActivityTimestamp),
    }));

    yield put(smartGoalsActions.setMeasures(measures));
    yield put(smartGoalsActions.setMeasuresLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on smart goals fetching');
    yield put(smartGoalsActions.setMeasuresLock(LoadingStatus.ERROR));
  }
}

function* fetchAvailableMeasures(
  action: PayloadAction<IMeasuresAvailableFetchPayload>
): Generator<any, void, MeasuresAvailableResponse> {
  const { smartGoalID, competenceID } = action.payload;

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal/measure/available', 'get', {
      query: {
        smart_goal_id: smartGoalID,
        competence_id: competenceID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<MeasuresAvailableResponse> = toCamelCase(response);
    const measures = camelizeResponse.measures;

    yield put(smartGoalsActions.setAvailableMeasures(measures));
  } catch (error) {
    console.error('Error on smart goals fetching');
  }
}

function* addMeasure(action: PayloadAction<IMeasureAddPayload>): Generator<any, void, any> {
  const { measureID, smartGoalID } = action.payload;

  yield put(smartGoalsActions.setUpdateMeasureLock(LoadingStatus.LOADING));

  try {
    const request: MeasureAddRequest = {
      json: {
        smart_goal_id: smartGoalID,
        measure_id: measureID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal/measure',
      'post',
      {
        ...request,
      },
      null,
      true
    );

    yield put(smartGoalsActions.setUpdateMeasureLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on delete measure', error);

    yield put(smartGoalsActions.setUpdateMeasureLock(LoadingStatus.ERROR));
  }
}

function* fetchSmartGoalCompetencies(
  action: PayloadAction<ISmartGoalCompetenciesFetchPayload>
): Generator<any, void, SmartGoalCompetenciesResponse> {
  const { smartGoalID, parentID } = action.payload;

  try {
    const response = yield call(restCall, '/funding_cycle/smart_goal/competencies', 'get', {
      query: {
        smart_goal_id: smartGoalID,
        parent_id: parentID,
      },
      ...authAdd(),
    });

    const camelizeResponse: Camelize<SmartGoalCompetenciesResponse> = toCamelCase(response);
    const smartGoalCompetencies = camelizeResponse.competencies;

    yield put(smartGoalsActions.setSmartGoalCompetencies(smartGoalCompetencies));

    yield put(smartGoalsActions.setSmartGoalDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.error('Error on smart goal details fetching');
    yield put(smartGoalsActions.setSmartGoalDetailsLock(LoadingStatus.ERROR));
  }
}

function* deleteMeasure(action: PayloadAction<IMeasureDeletePayload>): Generator<any, void, any> {
  const { measureID, smartGoalID } = action.payload;

  yield put(smartGoalsActions.setUpdateMeasureLock(LoadingStatus.LOADING));

  try {
    const request: MeasureDeleteRequest = {
      query: {
        smart_goal_id: smartGoalID,
        measure_id: measureID,
      },
      ...authAdd(),
    };

    yield call(
      restCall,
      '/funding_cycle/smart_goal/measure',
      'delete',
      {
        ...request,
      },
      null,
      true
    );

    yield put(smartGoalsActions.setUpdateMeasureLock(LoadingStatus.LOADED));

    yield put(
      smartGoalsActions.fetchMeasures({
        smartGoalID: smartGoalID,
      })
    );
  } catch (error) {
    console.error('Error on delete measure', error);
    yield put(smartGoalsActions.setUpdateMeasureLock(LoadingStatus.ERROR));
  }
}

export const smartGoalsSagas = [
  takeLatest(smartGoalsActions.fetchSmartGoals, fetchSmartGoals),
  takeLatest(smartGoalsActions.fetchSmartGoalDetails, fetchSmartGoalDetails),
  takeLatest(smartGoalsActions.createSmartGoal, createSmartGoal),
  takeLatest(smartGoalsActions.updateSmartGoal, updateSmartGoal),
  takeLatest(smartGoalsActions.deleteSmartGoal, deleteSmartGoal),

  takeLatest(smartGoalsActions.fetchMeasures, fetchMeasures),
  takeLatest(smartGoalsActions.fetchAvailableMeasures, fetchAvailableMeasures),
  takeLatest(smartGoalsActions.deleteMeasure, deleteMeasure),
  takeLatest(smartGoalsActions.addMeasure, addMeasure),
  takeLatest(smartGoalsActions.fetchSmartGoalCompetencies, fetchSmartGoalCompetencies),
];
