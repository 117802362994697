import { call, put, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import { Parameter } from '@/core/enums/parametersEnum';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { GetProfileDocument, GetProfileQuery, GetProfileQueryHookResult } from '@/services/graphql/system/graphql';
import { IUser } from '@/types/user';

import { parametersActions } from '../parameters/parametersSlice';
import { userSettingsActions } from '../userSettings/userSettingsSlice';


function* initializeProfile(): Generator<any, void, any> {
  yield put (authActions.fetchSelfProfile());
  yield put (
        parametersActions.fetchParameters([
          Parameter.TableEvenBackground,
          Parameter.TableOddBackground,
          Parameter.SelfAssessmentEvenBackgroundColor,
          Parameter.SelfAssessmentOddBackgroundColor,
          Parameter.InternalDifferentiationItemEvenBackground,
          Parameter.InternalDifferentiationItemOddBackground,
          Parameter.VocationalTrainingAreaEvenItemBackgroundColor,
          Parameter.VocationalTrainingAreaOddItemBackgroundColor,
          Parameter.AdditionalPersonRequirementConfirmCreatingText,
          Parameter.AdditionalPersonRequirementResponsibleLeaderRequired,
          Parameter.PerformanceProfileNoAllocatedCatalogs,
          Parameter.IsMnTerminWirksamkeitPflicht,
        ]))
} 


function* fetchSelfProfile(): Generator<any, void, GetProfileQueryHookResult> {
  const client = initializeApollo();

  const response = yield call(client.query<GetProfileQuery>, {
    query: GetProfileDocument,
    errorPolicy: 'ignore',
    context: { isSystem: true },
  });

  const user: IUser | null = (response.data && response.data.users_me) ? {
    avatar: {
      filename_disk: response.data.users_me.avatar?.filename_disk ?? '',
    },
    title: response.data.users_me.title ?? '',
    id: response.data.users_me.id,
    email: response.data.users_me.email ?? '',
    last_name: response.data.users_me.last_name ?? '',
    first_name: response.data.users_me.first_name ?? '',
    settingsID: response.data.users_me.settings?.id ?? '',
  } : null;

  yield put(authActions.setUser(user));
  yield put(userSettingsActions.fetchUserSettings());

}

export const authSagas = [
  takeLatest(authActions.fetchSelfProfile,
    fetchSelfProfile),
    takeLatest(authActions.initializeProfile, initializeProfile)
];
