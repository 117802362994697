import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { dropdownItemsActions } from '@/core/redux/slices/dropdownItems/dropdownItems.slice';
import {
  diagnosticsTypeOfDisabilityActions,
  ITypeOfDisabilityFetchPayload,
  ITypeOfDisabilityImportPayload,
  ITypeOfDisabilityUpdatePayload,
} from '@/core/redux/slices/functions/diagnostics/typeOfDisability/slice';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDateTime } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type TypeOfDisabilityResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/diagnostics/disability_data',
  'get',
  '200'
>;
type TypeOfDisabilityFetchRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/diagnostics/disability_data',
  'get'
>;

type TypeOfDisabilityImportDataResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/diagnostics/icd_codes/import',
  'get',
  '200'
>;
type TypeOfDisabilityFetchImportDataRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/diagnostics/icd_codes/import',
  'get'
>;

type TypeOfDisabilityUpdateRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/diagnostics/disability_data',
  'post'
>;

function* fetchTypeOfDisability(
  action: PayloadAction<ITypeOfDisabilityFetchPayload>
): Generator<any, void, TypeOfDisabilityResponse> {
  const { personID } = action.payload;

  try {
    yield put(diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityLock(LoadingStatus.LOADING));

    const request: TypeOfDisabilityFetchRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/diagnostics/disability_data', 'get', request);
    const mappedResponse = toCamelCase(response.disability_data);

    yield put(diagnosticsTypeOfDisabilityActions.setTypeOfDisability(mappedResponse));

    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.DISABILITY_TYPE,
      })
    );
    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.DISABILITY_PERCENTAGE,
      })
    );
    yield put(
      dropdownItemsActions.fetchDropdownItems({
        dropdownTable: DropdownItemsByFetch.IDENTITY_CARD_AUTHORITIES,
      })
    );

    yield put(diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on diagnostics type of disability fetch', error);
    yield put(diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityLock(LoadingStatus.ERROR));
  }
}

function* fetchTypeOfDisabilityImportData(
  action: PayloadAction<ITypeOfDisabilityImportPayload>
): Generator<any, void, TypeOfDisabilityImportDataResponse> {
  const { personID } = action.payload;

  try {
    yield put(
      diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityImportDataLock(LoadingStatus.LOADING)
    );

    const request: TypeOfDisabilityFetchImportDataRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/diagnostics/icd_codes/import', 'get', request);

    yield put(diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityImportData(response));

    yield put(
      diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityImportDataLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics type of disability import', error);
    yield put(
      diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityImportDataLock(LoadingStatus.ERROR)
    );
  }
}

function* updateTypeOfDisability(
  action: PayloadAction<ITypeOfDisabilityUpdatePayload>
): Generator<any, void, any> {
  const { personID, formValues } = action.payload;

  try {
    yield put(
      diagnosticsTypeOfDisabilityActions.setUpdateTypeOfDisabilityLock(LoadingStatus.LOADING)
    );

    const request: TypeOfDisabilityUpdateRequest = {
      json: {
        person_id: personID,
        disability_type_id: formValues?.disabilityTypeID,
        disability_percentage_id: formValues?.disabilityPercentageID,
        note: formValues?.note,
        emergency_info: formValues?.emergencyInfo,
        is_disabled_pass: formValues?.isDisabledPass,
        identity_card_authorities_id: formValues?.identityCardAuthoritiesID,
        valid_date: formValues?.validDate ? toBackendDateTime(formValues?.validDate) : null,
        is_unlimited: formValues?.isUnlimited,
        reference_number: formValues?.referenceNumber,
      },
      ...authAdd(),
    };

    yield call(restCall, '/diagnostics/disability_data', 'post', request);

    yield put(
      diagnosticsTypeOfDisabilityActions.setUpdateTypeOfDisabilityLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on diagnostics type of disability update', error);
    yield put(
      diagnosticsTypeOfDisabilityActions.setUpdateTypeOfDisabilityLock(LoadingStatus.ERROR)
    );
  }
}

export const diagnosticsTypeOfDisabilitySagas = [
  takeLatest(diagnosticsTypeOfDisabilityActions.fetchTypeOfDisability, fetchTypeOfDisability),
  takeLatest(diagnosticsTypeOfDisabilityActions.updateTypeOfDisability, updateTypeOfDisability),
  takeLatest(
    diagnosticsTypeOfDisabilityActions.fetchTypeOfDisabilityImportData,
    fetchTypeOfDisabilityImportData
  ),
];
