import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { Button } from '@/components/Button/Button';
import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { useVocationalTrainingAreaErrors } from '@/components/functions/VocationalTrainingArea/hooks/useVocationalTrainingAreaErrors';
import { CheckedCircleIcon } from '@/components/Icons/CheckedCircleIcon';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { DocumentViewIcon } from '@/components/Icons/DocumentViewIcon';
import { EmptyMeasuresIcon } from '@/components/Icons/EmptyMeasuresIcon';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { smartGoalsSelectors } from '@/core/redux/slices/functions/fundingCycle/smartGoals/selectors';
import { smartGoalsActions } from '@/core/redux/slices/functions/fundingCycle/smartGoals/smartGoalsSlice';
import { vocationalTrainingAreaActions } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { smartGoalsModalActions } from '@/core/redux/slices/modalsSlice/functions/fundingCycle/smartGoals/smartGoalsModalSlice';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

import { FundingCycleSection } from '../../FundingCycleSection/FundingCycleSection';
import { FundingCycleSectionHeader } from '../../FundingCycleSectionHeader/FundingCycleSectionHeader';

import styles from './../../styles.module.scss';
interface IMeasuresSection {
  className?: string;
  isControled?: boolean;
  controls?: ReactNode;
}

export const MeasuresSection: React.FC<IMeasuresSection> = ({
  className,
  controls,
  isControled = false,
}) => {
  const { t: smartGoalsTranslations } = useTranslation('smartGoals');
  const dispatch = useAppDispatch();

  const data = useAppSelector(smartGoalsSelectors.measures);
  const measuresLock = useAppSelector(smartGoalsSelectors.measuresLock);
  const updateMeasureLock = useAppSelector(smartGoalsSelectors.updateMeasureLock);
  const selectedSmartGoalID = useAppSelector(smartGoalsSelectors.selectedSmartGoal);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isShowFinished, setIsShowFinished] = useState<boolean>(false);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [isAnyChecked, setIsAnyChecked] = useState<boolean>(false);

  const measureIDs = useMemo(() => data.map((measure) => measure.id), [data]);

  const isLoading = measuresLock === LoadingStatus.LOADING;
  const isEmptyData = !data || !data.length;
  const isRenderSelect = isControled && !isShowFinished;

  const handleCheckAll = () => {
    if (isAllChecked) {
      // Deselect all rows
      setSelectedRows([]);
    } else {
      // Select all rows
      setSelectedRows(measureIDs);
    }

    // Toggle the all-checked and any-checked states accordingly
    setIsAllChecked(!isAllChecked);
    setIsAnyChecked(false); // Since all or none are selected, no intermediate state
  };

  const handleSelectRow = (rowId: number) => {
    const isRowSelected = selectedRows.includes(rowId);

    // Update selected rows based on whether the row is already selected
    const updatedSelectedRows = isRowSelected
      ? selectedRows.filter((id) => id !== rowId)
      : [...selectedRows, rowId];

    setSelectedRows(updatedSelectedRows);

    const allRowsCount = measureIDs.length;
    const selectedCount = updatedSelectedRows.length;

    // Update the isAllChecked and isAnyChecked states
    setIsAllChecked(selectedCount === allRowsCount);
    setIsAnyChecked(selectedCount > 0 && selectedCount < allRowsCount);
  };

  const handleDeleteMeasure = (id: number) => {
    if (!selectedSmartGoalID) {
      return;
    }

    dispatch(
      smartGoalsModalActions.openConfirmDeleteMeasureModal({
        measureID: id,
        smartGoalID: selectedSmartGoalID,
      })
    );
  };

  const handleCompleteMeasure = (id: number) => {
    dispatch(vocationalTrainingAreaActions.completeMeasures([id]));
  };

  const handleOpenDocumentsModal = (id: number) => {
    dispatch(
      vocationalTrainingAreaModalActions.openVocationalTrainingAreaDocuments({
        measureID: id,
      })
    );
  };

  const resetSelection = () => {
    dispatch(smartGoalsActions.setMeasures([]));
    setSelectedRows([]);
    setIsAllChecked(false);
    setIsAnyChecked(false);
  };

  const columns: ITableColumn[] = [
    {
      header: '',
      accessor: 'name',
      isSortable: false,
      className: 'w-full  max-w-[850px] whitespace-normal py-3 px-5 pl-6',
      template: (value, row) => {
        return (
          <div
            className='flex gap-2.5 items-center'
            onClick={() => isRenderSelect && handleSelectRow(row.id)}
          >
            {isRenderSelect && (
              <CheckboxUI checked={selectedRows.includes(row.id)} id={row.id} name={row.id} />
            )}
            <Typography type='14-R'>{value}</Typography>
          </div>
        );
      },
    },
    {
      header: '',
      accessor: '',
      isSortable: false,
      className: 'py-1 px-5 pr-6',
      template: (_, row) => {
        const { hasDocuments, canBeDeleted } = row;

        return (
          <div className={'flex flex-row items-center'}>
            {isControled && (
              <>
                <Button
                  data-tooltip-id='completeMeasure'
                  data-tooltip-content={smartGoalsTranslations(
                    'columns.actions.completeMeasure.tooltip.label'
                  )}
                  buttonVariant={'Icon'}
                  buttonStyle={'Icon'}
                  className={`text-${colorTypes.Grey}  p-2 hover:bg-stroke  rounded-md`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCompleteMeasure(row.measureToPersonId);
                  }}
                >
                  <CheckedCircleIcon className={'w-[24px] h-[24px]'} />
                </Button>
                <Tooltip id={'completeMeasure'} style={{ zIndex: 100000 }} />

                <Button
                  buttonStyle={'Icon'}
                  buttonVariant={'Icon'}
                  disabled={!hasDocuments}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDocumentsModal(row.id);
                  }}
                  className={twMerge(
                    `text-${colorTypes.Grey}  p-2 hover:bg-stroke  rounded-md`,
                    !hasDocuments && 'pointer-events-none'
                  )}
                >
                   <DocumentViewIcon className={!hasDocuments ? 'invisible' : ''}/>
                </Button>
              </>
            )}
            <>
              <Button
                data-tooltip-id='deleteMeasure'
                data-tooltip-content={smartGoalsTranslations(
                  'columns.actions.deleteMeasure.tooltip.label'
                )}
                buttonVariant={'Icon'}
                buttonStyle={'Icon'}
                className={twMerge(
                  `text-${colorTypes.Grey}  p-2 hover:bg-stroke hover:text-error rounded-md`,
                  !canBeDeleted && 'pointer-events-none'
                )}
                disabled={!canBeDeleted}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteMeasure(row.id);
                }}
              >
                <CrossIcon className={'w-[24px] h-[24px]'} />
              </Button>
              <Tooltip id={'deleteMeasure'} style={{ zIndex: 100000 }} />
            </>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(smartGoalsActions.setSelectedMeasures(selectedRows));
  }, [selectedRows]);

  useEffect(() => {
    if (!selectedSmartGoalID) {
      resetSelection();
      return;
    }

    dispatch(
      smartGoalsActions.fetchMeasures({
        smartGoalID: selectedSmartGoalID,
        isFinished: isShowFinished,
      })
    );

    resetSelection();
  }, [selectedSmartGoalID, isShowFinished]);

  useEffect(() => {
    if (updateMeasureLock === LoadingStatus.LOADED && selectedSmartGoalID) {
      dispatch(
        smartGoalsActions.fetchMeasures({
          smartGoalID: selectedSmartGoalID,
          isFinished: isShowFinished,
        })
      );
    }
  }, [updateMeasureLock]);

  useEffect(() => {
    return () => {
      dispatch(smartGoalsActions.setMeasures([]));
      dispatch(smartGoalsActions.setSelectedMeasures([]));
    };
  }, []);

  const measuresTitle = (
    <div className='flex gap-5 h-full items-center'>
      <div
        className={twMerge(
          'flex gap-2.5 items-center cursor-pointer',
          !isRenderSelect && 'pointer-events-none'
        )}
        onClick={handleCheckAll}
      >
        {isRenderSelect && (
          <CheckboxUI checked={isAllChecked} intermediate={isAnyChecked} id='all' name='all' />
        )}
        <Typography type={'H3'}>{smartGoalsTranslations('measures.label')}</Typography>
      </div>
      <div className='w-[1px] bg-stroke h-[28px]'></div>
      <div
        className='flex gap-2.5 cursor-pointer'
        onClick={() => {
          setIsShowFinished(!isShowFinished);
        }}
      >
        <CheckboxUI checked={isShowFinished} />
        <Typography>{smartGoalsTranslations('filters.isCompleted.label')}</Typography>
      </div>
    </div>
  );

  const renderEmptyMeasures = useCallback(() => {
    return (
      <div className='flex flex-col gap-1 items-center justify-center h-full'>
        <EmptyMeasuresIcon />
        <Typography>
          {!isShowFinished
            ? smartGoalsTranslations('measuresTable.empty.label')
            : smartGoalsTranslations('measuresTable.emptyCompleted.label')}
        </Typography>
      </div>
    );
  }, [isShowFinished]);

  return (
    <>
      <FundingCycleSection
        className={className}
        header={<FundingCycleSectionHeader title={measuresTitle} controls={controls} />}
      >
        {selectedSmartGoalID && !isEmptyData && (
          <Table
            data={data}
            columns={columns}
            selectable={false}
            rowClassName={styles.row}
            isShowTableHead={false}
          />
        )}
        {!isLoading && isEmptyData && renderEmptyMeasures()}
      </FundingCycleSection>
    </>
  );
};
