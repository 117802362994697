import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { Parameter } from '@/core/enums/parametersEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { authSelectors } from '@/core/redux/slices/auth/selectors';
import { parametersActions } from '@/core/redux/slices/parameters/parametersSlice';
import { userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';

const useAuthUserLoader = (): boolean => {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(authSelectors.accessToken);
  const profileEntity = useAppSelector(authSelectors.profileEntity);

  useEffect(() => {
    if (!fp.isEmpty(accessToken) && fp.isEmpty(profileEntity)) {
      dispatch(authActions.initializeProfile());
    }
  }, [dispatch, accessToken, profileEntity]);

  return useMemo(() => !fp.isEmpty(profileEntity), [profileEntity]);
};

export default useAuthUserLoader;
