import React from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { Namespace, TFunction } from 'i18next';

import { FormRadioButtonGroup, IFormRadioButtonGroup } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { Input } from '@/components/Input/Input';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { CheckboxGroup, CheckboxItem, CheckboxOption, ICheckboxItem } from '../CheckboxItem/CheckboxItem';
import { DatePicker } from '../Datepicker/DatePicker';
import { ExternalSelectForm } from '../Select/form/ExternalSelectForm';
import { ISelectItem } from '../Select/SelectItem/SelectItem';

type NestedKey<T> = T extends object
  ? {
    [K in keyof T]: K extends string
      ? `${K}` | (T[K] extends object ? `${K}.${NestedKey<T[K]>}` : never)
      : never;
  }[keyof T]
  : never;

export interface FormItem<T = any> {
  id?: string;
  name: NestedKey<T>;
  type: string;
  label?: string;
  labelParams?: any;
  options?: any[];
  radioButtonGroupOptions?: Omit<IFormRadioButtonGroup, 'control'>;
  checkBoxOptions?: Omit<ICheckboxItem, 'fieldName' | 'label'>;
  className?: string;
  tableName?: string;
  layout?: 'horizontal' | 'vertical';
  isReversed?: boolean;
  customComponent?: React.ReactNode;
  condition?: boolean;
}

export interface FormGroup<T = any> {
  fields: (FormItem<T> | FormGroup<T>)[];
  className?: string;
  id?: string;
  title?: string;
  fieldsContainerClassName?: string;
  condition?: boolean;
}

interface Props<T = any> {
  title?: string;
  className?: string;
  list: (FormItem<T> | FormGroup<T>)[];
  translationContext?: TFunction<Namespace, undefined>;
}

const FormRender = <T = any>({ title, list, className, translationContext }: Props<T>) => {
  const { control } = useFormContext();

  const renderFormItem = (item: FormItem<T>, index: number) => {
    if (item.condition === false) return null;

    const {
      name,
      type,
      radioButtonGroupOptions,
      checkBoxOptions,
      options,
      tableName,
      label,
      className,
      customComponent: CustomComponent,
      isReversed,
      layout = 'horizontal',
      labelParams = {},
    } = item;

    return (
      <div className={clsx(twMerge('w-full flex flex-col gap-2', className))} key={CustomComponent ? `custom-${index}` : name}>
        {label && (
          <Typography as='label' htmlFor={CustomComponent ? `custom-${index}` : name} className='truncate h-6'>
            {translationContext ? String(translationContext(label as any, { ...labelParams })) : label}
          </Typography>
        )}
        <div className={twMerge(isReversed && '-order-1')}>
          {(() => {
            switch (type) {
              case 'input':
                return <Input control={control.register(name)} />;
              case 'custom':
                return CustomComponent;
              case 'number':
                return <Input control={control.register(name)} type='number' />;
              case 'textarea':
                return <TextArea control={control.register(name)} />;
              case 'select':
                const selectOptions: ISelectItem[] | undefined = options?.map(
                  (item): ISelectItem => ({
                    ...item,
                    component: item.name,
                  })
                );

                return (
                  <SelectForm
                    values={selectOptions}
                    control={control.register(name)}
                    className='w-full'
                    isFlexible
                  />
                );
              case 'radio-button-group':
                return <FormRadioButtonGroup control={control.register(name)} {...radioButtonGroupOptions} />;
              case 'external-select':
                return (
                  <ExternalSelectForm
                    tableName={tableName}
                    control={control.register(name)}
                    className='w-full'
                    isFlexible
                  />
                );
              case 'datepicker':
                return <DatePicker control={control.register(name)} />;
              case 'checkbox':
                return <CheckboxItem fieldName={name} label={undefined} {...checkBoxOptions} />;
              case 'checkbox_group':
                const translatedOptions = options?.map((item) => ({
                  ...item,
                  name: translationContext ? translationContext(item.name as any) : item.name,
                }));

                return (
                  <CheckboxGroup
                    name={name}
                    options={translatedOptions as CheckboxOption[]}
                    layout={layout}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  };

  const renderFormGroup = (group: FormGroup<T>) => {
    if (group.condition === false) return null;

    return (
      <div className={twMerge('flex flex-col gap-2', group.className)} key={group.id}>
        {group.title && (
          <Typography type='H3'>
            {translationContext ? translationContext(group.title as any) : group.title}
          </Typography>
        )}
        <div className={twMerge('flex', group.fieldsContainerClassName)}>
          {group.fields.map((field, index) =>
            'fields' in field ? renderFormGroup(field) : renderFormItem(field, index)
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={clsx('flex flex-col gap-4')}>
      {title && (
        <div className={`mb-5 border-b border-b-${colorTypes.StrokeLight} pb-2.5`}>
          <Typography type='H3'>{title}</Typography>
        </div>
      )}

      <div className={twMerge('flex flex-wrap gap-y-6', className)}>
        {list.map((item, index) => ('fields' in item ? renderFormGroup(item) : renderFormItem(item, index)))}
      </div>
    </div>
  );
};

export default FormRender;
