import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { diagnosticsTypeOfDisabilitySelectors } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/selectors';
import { diagnosticsTypeOfDisabilityActions } from '@/core/redux/slices/functions/diagnostics/typeOfDisability/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';

export interface ITypeOfDisabilityForm {
  isDisabledPass: boolean;
  isUnlimited: boolean;
  disabilityTypeID?: number;
  disabilityPercentageID?: number;
  note?: string | null;
  emergencyInfo?: string | null;
  identityCardAuthoritiesID?: number;
  validDate?: string | null;
  referenceNumber?: string | null;
}

export function useTypeOfDisabilityForm() {
  const form = useForm<ITypeOfDisabilityForm>();

  const typeOfDisability = useAppSelector(diagnosticsTypeOfDisabilitySelectors.typeOfDisability);
  const typeOfDisabilityImportData = useAppSelector(
    diagnosticsTypeOfDisabilitySelectors.typeOfDisabilityImportData
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!typeOfDisability) {
      return;
    }

    const newState: ITypeOfDisabilityForm = {
      disabilityPercentageID: typeOfDisability.disabilityPercentage?.id,
      disabilityTypeID: typeOfDisability.disabilityType?.id,
      emergencyInfo: typeOfDisability.emergencyInfo,
      identityCardAuthoritiesID: typeOfDisability.identityCardAuthorities?.id,
      isDisabledPass: typeOfDisability.isDisabledPass,
      isUnlimited: typeOfDisability.isUnlimited,
      note: typeOfDisability.note,
      referenceNumber: typeOfDisability.referenceNumber,
      validDate: toClientDateInput(typeOfDisability.validDate),
    };

    form.reset(newState);
  }, [typeOfDisability]);

  useEffect(() => {
    if (!typeOfDisabilityImportData) {
      return;
    }

    const codes = typeOfDisabilityImportData.codes
      .map((code) => `${code.id} ${code.code} ${code.description}`)
      .join('\n');

    form.setValue('note', codes, { shouldDirty: true });

    dispatch(diagnosticsTypeOfDisabilityActions.setTypeOfDisabilityImportData(null));
  }, [typeOfDisabilityImportData]);

  return form;
}
